import * as React from "react";
import { DocsItem } from "./docs-item";

type Props = { initiallyOpen: boolean };

export function FaqDocsItem({ initiallyOpen }: Props) {
  return (
    <DocsItem header={"FAQ"} initiallyOpen={initiallyOpen}>
      <h4>Why do you need so many permissions?</h4>
      <p>
        Song Compass interacts with your Spotify account to show you what's
        currently playing and create playlists. When logging in the first time
        the application will ask you for permission to interact with Spotify.
        All the permissions requested are needed to support a rich experience.
      </p>
    </DocsItem>
  );
}
