import * as React from "react";
import { DocsItem } from "./docs-item";

type Props = { initiallyOpen: boolean };
export function RoadmapDocsItem({ initiallyOpen }: Props) {
  return (
    <DocsItem header={"Roadmap"} initiallyOpen={initiallyOpen}>
      <ul>
        <li>Better tooltips</li>
        <li>Keyboard shortcuts</li>
        <li>View details of created playlists</li>
        <li>Insights into relation between artists</li>
        <li>Preview tracks feature</li>
        <li>Create top song for each related genre</li>
        <li>List more information in the playlist descriptions</li>
      </ul>
    </DocsItem>
  );
}
