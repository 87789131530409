export class Utils {
  static generateRandomNaturalNumber() {
    return Math.round(Math.random() * 100000);
  }

  static resolveAfter2Seconds() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 2000);
    });
  }

  static resolveAfter5Seconds() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 5000);
    });
  }

  static resolveAfter3Seconds() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 3000);
    });
  }

  static resolveAfter10Seconds() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 10000);
    });
  }

  static resolveAfter1Seconds() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 1000);
    });
  }
  static resolveAfter500Millis() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 500);
    });
  }
}
