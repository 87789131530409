import { WhatsPlaying } from "spotify-utils";
import { SpotifyPlaylistCommon } from "spotify-utils/dist/src/domain/SpotifyPlaylist";
import { DateUtils } from "../date-utils";
import {
  ISpotifyState,
  PlaylistsStore,
  TrackPlayerStore
} from "../types/index";

export class ReducerUtils {
  private readonly whatsPlaying?: WhatsPlaying;
  private readonly trackPlayer: TrackPlayerStore;
  private readonly playlists: PlaylistsStore;

  constructor(spotifyState: ISpotifyState) {
    this.whatsPlaying = spotifyState.whatsPlaying;
    this.trackPlayer = spotifyState.trackPlayer;
    this.playlists = spotifyState.playlistStore;
  }

  isThereAPlaylistPlaying() {
    if (this.whatsPlaying === undefined) {
      return false;
    }

    return this.whatsPlaying.isThereAPlaylistContext();
  }

  getNumberOfTracksInPlaylist() {
    return this.trackPlayer.focusedPlaylist!.getNumberOfItemsInPlaylist();
  }

  public getNextTrackIndex() {
    return this.getCurrentTrackIndex() + 1;
  }

  private isDonePlayingNow() {
    const currentTrackIndex = this.getCurrentTrackIndex();
    return this.getNumberOfTracksInPlaylist() === currentTrackIndex + 1;
  }

  private getCurrentTrackIndex(): number {
    return this.trackPlayer.currentlyPlayingTrackIndex;
  }

  isEndTimeDefined() {
    const endTime = this.trackPlayer.targetEndTime;
    return endTime !== undefined;
  }

  isTrackPlaying() {
    return this.trackPlayer.isPlaying;
  }

  isItTimeForNextTrack() {
    return DateUtils.isXAfterY(new Date(), this.trackPlayer.targetEndTime!);
  }

  areTheTracksDonePlaying() {
    if (!this.isTrackPlaying()) {
      return true;
    }
    return this.isItLastTrack() && this.isItTimeForNextTrack();
  }

  private isItLastTrack() {
    return (
      this.getCurrentTrackIndex() + 1 === this.getNumberOfTracksInPlaylist()
    );
  }

  getCurrentlyPlayingTrack() {
    return this.trackPlayer.focusedPlaylist!.getTrackList().getTracks()[
      this.trackPlayer.currentlyPlayingTrackIndex
      ];
  }

  getPercentageTimeRemaining() {
    if (!this.isTrackPlaying()) {
      return 0;
    }
    const startTime = this.getStartTime();
    const endTime = this.getEndTime();
    const currentTime = new Date();
    if (DateUtils.isXAfterY(currentTime, endTime)) {
      return 1;
    }
    const percentageDone = DateUtils.getPerecentageDone(
      startTime,
      currentTime,
      endTime
    );
    return percentageDone;
  }

  private getStartTime() {
    return this.trackPlayer.startTime as Date;
  }

  private getEndTime() {
    return this.trackPlayer.targetEndTime as Date;
  }

  getFirstPageOfPlaylists() {
    return this.playlists;
  }

  getPlaylistStoreWithoutPlaylist(
    playlistStore: PlaylistsStore,
    payload: SpotifyPlaylistCommon
  ): PlaylistsStore {
    const playlists = [...playlistStore.playlists];
    const playlistsFiltered = playlists.filter(p => p.getId() !== payload.getId());
    return {
      ...playlistStore,
      playlists: playlistsFiltered
    };
  }
}
