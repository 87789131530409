import * as constants from "../constants/constants";
import { WindowUtils } from "../utils/WindowUtils";

export class ApplicationException {
  public details: string;
  public error: any;
  public message: string;

  constructor(args: { message: string; details: string; error: Error }) {
    this.message = args.message;
    this.details = args.details;
    this.error = args.error;
  }

  private generateAction() {
    return {
      type: constants.ERROR_PUBLISHED,
      applicationException: this
    };
  }

  dispatchAndRedirectToErrorPage(dispatch: any) {
    const action = this.generateAction();
    dispatch(action);
    WindowUtils.goToErrorPage();
  }
}
