import * as React from "react";
import { MdCheckCircle } from "react-icons/md";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { Dispatch } from "redux";
import "../../index.scss";
import { IStoreState } from "../../types/index";
import { GenericNotification } from "./GenericNotification";

type State = { currentTime: Date };

class Raw extends React.Component<Props, State> {
  private clearInterval?: number;
  constructor(props: Props) {
    super(props);
    this.state = {
      currentTime: new Date(),
    };
  }

  public componentWillUnmount(): void {
    window.clearInterval(this.clearInterval!);
  }

  public componentDidMount() {
    const that = this;
    this.clearInterval = window.setInterval(() => {
      that.setState({
        currentTime: new Date(),
      });
    }, 1000);
  }

  public render() {
    const { notification } = this.props;
    let notificationClasses = "notification  recoverable-notification";

    const shouldShowRightPanel = notification.showTimer;

    return (
      <div className={notificationClasses} key={notification.uniqueId}>
        <div className="leftPanel">
          <div>{notification.message}</div>
          <div>{this.props.children}</div>
        </div>
        {shouldShowRightPanel && (
          <div className="rightPanel">
            <span>
              {notification.showTimer &&
                notification.getTimeSinceOccurrence(this.state.currentTime)}
            </span>
            <MdCheckCircle
              className="icon-button"
              onClick={this.props.acknowledge.bind(notification)}
            />
          </div>
        )}
      </div>
    );
  }
}

type StateProps = {};
type DispatchProps = {
  acknowledge: any;
  dispatch: Dispatch<any>;
};
type ConnectedProps = DispatchProps & StateProps;
type OwnProps = {
  notification: GenericNotification;
  children?: any;
};
type Props = ConnectedProps & OwnProps;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, IStoreState> = (
  state
) => {
  return {};
};
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch
) => {
  return {
    dispatch: dispatch,
    acknowledge: function (this: GenericNotification) {
      dispatch<any>(this.generateAcknowledgeAction());
    },
  };
};

const NotificationItem = connect(mapStateToProps, mapDispatchToProps)(Raw);

export { NotificationItem };
