import * as React from "react";
import "./action-button.scss";

export type ActionButtonProps = {
  onClick: any;
  disabled: boolean;
  className?: string;
  tooltip?: JSX.Element;
};
export const ActionButton: React.FC<ActionButtonProps> = props => {
  function renderTooltipIfDefined() {
    if (!props.tooltip) {
      return null;
    }

    return <div className={"action-button-tooltip"}>{props.tooltip}</div>;
  }

  return (
    <div className={"action-button-wrapper"}>
      <button
        className={`action-button ${props.className ? props.className : ""}`}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        {props.children}
      </button>
      {renderTooltipIfDefined()}
    </div>
  );
};
