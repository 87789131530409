import * as React from "react";
import { SpotifyPlaylist } from "spotify-utils";
import { DeletePlaylistButton } from "../../header/delete-playlist-button";
import { PlayPlaylistButton } from "../../header/play-playlist-button";
import { PlayPlaylistOnRadioButton } from "../../header/play-playlist-on-radio-button";
import "./playlist-notification.scss";

export function PlaylistNotificationComponent(props: {
  playlist: SpotifyPlaylist;
}) {
  const playlist = props.playlist;
  const playlistLink = (
    <a
      href={playlist.getLinkToApp()}
      target={"_blank"}
      rel={"noopener noreferrer"}
    >
      {playlist.getName()}
    </a>
  );
  return (
    <div className={"playlist-notification"}>
      <div className={"playlist-notification-header"}>
        <PlayPlaylistOnRadioButton playlist={playlist} />
        <PlayPlaylistButton playlist={playlist} />
        {playlistLink}
      </div>
      <div>
        <DeletePlaylistButton playlist={props.playlist} />
      </div>
    </div>
  );
}
