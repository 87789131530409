import * as React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { DeviceList } from "spotify-utils";
import { RoutingHrefs } from "../../constants/routing-hrefs";
import { IStoreState } from "../../types/index";
import { ActionButtonGreen } from "../buttons/action-button-green";
import { SongCompassWhite } from "../images/song-compass-white";
import "./error-page.scss";

const NoActiveDevicesErrorPageRaw: React.FC<{
  deviceList?: DeviceList;
}> = props => {
  if (props.deviceList === undefined) {
    return <h2>Internal state error. You should not be here.</h2>;
  }
  const onClick = () => {
    console.log("open");
    window.open("https://open.spotify.com/collection/tracks", "_blank");
  };
  return (
    <div className="error-page">
      <SongCompassWhite />
      <h2>
        No Active Devices Running Spotify
        {/*<span className={"icon-with-margin"}>*/}
        {/*<FaRegSadTear />*/}
        {/*</span>*/}
      </h2>

      <br />

      <ActionButtonGreen onClick={onClick} disabled={false} className={""}>
        Activate this device by playing a song
      </ActionButtonGreen>
      {/*<DeviceSummary deviceList={props.deviceList}/>*/}
      <h2>
        <NavLink to={RoutingHrefs.docsHref}>Docs</NavLink>
      </h2>
      <h2>
        <NavLink to={RoutingHrefs.root}>Home</NavLink>
      </h2>
    </div>
  );
};

const NoActiveDevicesErrorPage = connect((state: IStoreState) => {
  return {
    deviceList: state.spotify.deviceList
  };
})(NoActiveDevicesErrorPageRaw);
export { NoActiveDevicesErrorPage };
