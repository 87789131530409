import * as React from "react";
import { connect } from "react-redux";
import { Route } from "react-router";
import { RoutingHrefs } from "../../constants/routing-hrefs";
import { IStoreState } from "../../types/index";
import { WindowUtils } from "../../utils/WindowUtils";
import ActionView from "../action-view/action-view";
import ArtistImage from "../details-view/artist-image";
import PlaybackStatus from "../details-view/playback-status";
import { Header } from "../header/header";
import { SongCompassWhite } from "../images/song-compass-white";

type Props = { loggedIn: boolean };
const MainAppRaw = (props: Props) => {
  if (!props.loggedIn) {
    WindowUtils.goToLoginScreen();
  }
  return (
    <div className="spotifyPanel">
      <Header />
      <Route
        path={RoutingHrefs.root}
        render={() => {
          return (
            <>
              <PlaybackStatus />
              {/*<DetailsView />*/}
              <ActionView />
              {props.loggedIn && <SongCompassWhite id={"compass"} />}
              <ArtistImage />
            </>
          );
        }}
        exact
      />
    </div>
  );
};

const MainApp = connect((state: IStoreState) => {
  return {
    loggedIn: state.spotify.isLoggedIn,
  };
})(MainAppRaw);

export { MainApp };
