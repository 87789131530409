import * as React from "react";

export function Top10TracksPerPlaylistDocs() {
  return (
    <p>
      This is useful if you've discovered a new artist you love and you want to
      explore their most popular songs
    </p>
  );
}
