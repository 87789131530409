import * as React from "react";
import { SpotifyArtist } from "spotify-utils";
import { ActionButtonGreen } from "../buttons/action-button-green";

export function ArtistTooltip(props: {
  artist: SpotifyArtist;
  playingArtistGenres: string[];
  onUserSelectArtist: () => void;
}) {
  return (
    <div className="tooltip">
      <h5>{props.artist.getNameOfArtist()}</h5>
      <h6>Popularity: {props.artist.getPopularity()}</h6>
      <p>{getGenreMatchingSummary(props.artist, props.playingArtistGenres)}</p>
      <ActionButtonGreen
        onClick={props.onUserSelectArtist.bind(props.artist)}
        disabled={false}
        className={""}
      >
        Select
      </ActionButtonGreen>
    </div>
  );
}

function getGenreMatchingSummary(
  artist: SpotifyArtist,
  playingArtistGenres: string[]
) {
  const matchingGenres = artist.getGenres().filter(function(genre) {
    return playingArtistGenres.includes(genre);
  });
  const totalMatching = matchingGenres.length;
  const totalPlayingGenres = playingArtistGenres.length;
  const percentage = Math.round((totalMatching / totalPlayingGenres) * 100);
  return `${totalMatching}/${totalPlayingGenres} (${percentage}%) matching genres.`;
}
