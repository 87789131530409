import * as React from "react";
import {
  FaHeart,
  FaPause,
  FaPlay,
  FaRegHeart,
  FaStepBackward,
  FaStepForward,
} from "react-icons/fa";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { SpotifyTrack, WhatsPlaying } from "spotify-utils";
import {
  addFiveSeconds,
  pauseTrack,
  playPreviewTrack,
  skipToNextPreviewTrack,
  skipToPreviousPreviewTrack,
  toggleSavedTrack,
} from "../../actions/general-actions";
import { ReducerUtils } from "../../reducers/reducer-utils";
import { IStoreState, TrackPlayerStore } from "../../types/index";
import ActionView from "../action-view/action-view";
import { ActionButton } from "../buttons/action-button";
import { ProgressRing } from "../progress-ring";
import { TrackList } from "../track-list/track-list";
import "./rapid-radio.scss";

type StateProps = {
  whatsPlaying?: WhatsPlaying;
  trackPlayer: TrackPlayerStore;
  reducerUtils: ReducerUtils;
};
type DispatchProps = {
  skipAhead: () => void;
  skipBack: () => void;
  addFiveSeconds: () => void;
  toggleSaveTrackToLibrary: (track: SpotifyTrack) => void;
  pauseTrack: () => void;
  playPreviewTrack: () => void;
};

type ConnectedProps = DispatchProps & StateProps;
type OwnProps = {};
type Props = ConnectedProps;

const RapidRadioControls: React.FC<Props> = (props) => {
  const reducerUtils = props.reducerUtils;

  function renderPlayOrPauseButton() {
    if (!reducerUtils.isTrackPlaying()) {
      return (
        <ActionButton
          className={"rr-play"}
          onClick={() => props.playPreviewTrack()}
          disabled={false}
        >
          <span>Play</span>
          <FaPlay />
        </ActionButton>
      );
    }
    return (
      <ActionButton
        className={"rr-pause"}
        onClick={() => props.pauseTrack()}
        disabled={false}
      >
        <span>Pause</span>
        <FaPause />
      </ActionButton>
    );
  }

  function renderLikeButton() {
    return (
      <ActionButton
        className={"rr-like-button"}
        onClick={() =>
          props.toggleSaveTrackToLibrary(
            reducerUtils.getCurrentlyPlayingTrack()
          )
        }
        disabled={false}
      >
        <span>Like Song</span>
        {reducerUtils.getCurrentlyPlayingTrack().doesUserLikeTrack() ? (
          <FaHeart />
        ) : (
          <FaRegHeart />
        )}
      </ActionButton>
    );
  }

  function renderWhatsPlaying() {
    return (
      <>
        {reducerUtils.getCurrentlyPlayingTrack().getArtistNames()} -{" "}
        {reducerUtils.getCurrentlyPlayingTrack().getTrackName()}
      </>
    );
  }

  function renderStepBackwards() {
    return (
      <ActionButton
        className={"rr-previous"}
        onClick={props.skipBack}
        disabled={false}
      >
        <span>Previous Song</span>
        <FaStepBackward />
      </ActionButton>
    );
  }

  function renderStepForwards() {
    return (
      <ActionButton
        className={"rr-next"}
        onClick={props.skipAhead}
        disabled={false}
      >
        <span>Next Song</span>
        <FaStepForward />
      </ActionButton>
    );
  }

  function renderAdd5SecondsIcon() {
    return (
      <ActionButton
        className={"rr-bomb"}
        onClick={props.addFiveSeconds}
        disabled={false}
      >
        <span>5 More Seconds!</span>
        <ProgressRing
          progress={props.trackPlayer.percentageDone * 100}
          radius={50}
          stroke={8}
        />
      </ActionButton>
    );
  }
  return (
    <>
      <div className={"rr-header"}>
        <h4>Radio Controls</h4>
      </div>
      <div className={"rr-body"}>
        {<div className={"rr-whats-playing"}>{renderWhatsPlaying()}</div>}
        <div className={"rr-controls"}>
          {renderLikeButton()}
          {renderPlayOrPauseButton()}
        </div>
        <div className={"rr-controls"}>
          {renderStepBackwards()}
          {renderStepForwards()}
          {renderAdd5SecondsIcon()}
        </div>
      </div>
    </>
  );
};

const Raw: React.FC<Props> = (props) => {

  if (props.trackPlayer.isLoading) {
    return <h2>Loading tracks..</h2>
  }

  if (!props.trackPlayer.focusedPlaylist) {
    return <h2>First find a playlist by clicking on red icon</h2>;
  }

  return (
    <div>
      <div className="rapid-radio-main">
        <div className="rapid-radio-first-column">
          <RapidRadioControls
            addFiveSeconds={props.addFiveSeconds}
            skipAhead={props.skipAhead}
            skipBack={props.skipBack}
            trackPlayer={props.trackPlayer}
            whatsPlaying={props.whatsPlaying}
            reducerUtils={props.reducerUtils}
            toggleSaveTrackToLibrary={props.toggleSaveTrackToLibrary}
            pauseTrack={props.pauseTrack}
            playPreviewTrack={props.playPreviewTrack}
          />
        </div>
        <div className="rapid-radio-second-column">
          <TrackList playlist={props.trackPlayer.focusedPlaylist} />
        </div>
        <div className={"rapid-radio-third-column"}>
          <div className="rapid-radio-artist-details">
            <ActionView />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, IStoreState> = (
  state
) => {
  return {
    whatsPlaying: state.spotify.whatsPlaying,
    trackPlayer: state.spotify.trackPlayer,
    reducerUtils: new ReducerUtils(state.spotify),
  };
};
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch
) => {
  return {
    skipAhead: () => {
      dispatch(skipToNextPreviewTrack());
    },
    skipBack: () => {
      dispatch(skipToPreviousPreviewTrack());
    },
    pauseTrack: () => {
      // dispatch(resumePlayback() as any);
      dispatch(pauseTrack());
    },
    playPreviewTrack: () => {
      // dispatch(pausePlayback({ ignoreIfNotPlaying: true }) as any);
      dispatch(playPreviewTrack());
    },
    addFiveSeconds: () => {
      dispatch(addFiveSeconds());
    },
    toggleSaveTrackToLibrary: (track: SpotifyTrack) => {
      dispatch(toggleSavedTrack(track) as any);
    },
  };
};
const RapidRadio = connect(mapStateToProps, mapDispatchToProps)(Raw);

export { RapidRadio };
