import { loadingBarReducer } from "react-redux-loading-bar";
import { combineReducers, Reducer } from "redux";
import { getType } from "typesafe-actions";
import {
  addFiveSeconds,
  checkToSeeIfShouldSkipToNextTrack,
  eventLoop,
  pauseTrack, playlistDeleted,
  playPreviewTrack,
  receivedPlaylistForRapidRadio,
  skipToNextPreviewTrack,
  skipToPreviousPreviewTrack,
  SpotifyActions,
  startPlayingPlaylistRadio,
  startPlayingTracks,
  toggleSaveTrackToLibrary,
  updateArtistPlaying,
  updatePreviewTrackPlaying
} from "../actions/general-actions";
import { GeneralActions, PlaylistNotification } from "../components/notifications/GenericNotification";
import {
  ACKNOWLEDGE_NOTIFICATION,
  defaultDurationMs,
  ERROR_PUBLISHED,
  LOGIN,
  OPEN_DRAWER,
  PERFORMED_ACTION,
  PERFORMING_AN_ACTION,
  RECEIVED_ARTIST_PLAYLIST,
  RECEIVED_ARTIST_PLAYLIST_SINGULAR,
  RECEIVED_DEVICE_LIST_SUCCESS,
  RECEIVED_GENRE_PLAYLIST,
  RECEIVED_PLAYBACK_SUCCESS,
  RECEIVED_PLAYLIST_SUCCESS,
  RECEIVED_USER_SUCCESS,
  REQUEST_USER_REQUEST,
  SCROLL_TO_PLAYLIST_VIEW,
  TOGGLE_DRAWER,
  USER_SELECTED_ARTIST
} from "../constants/constants";
import { DateUtils } from "../date-utils";
import { IGeneralState, ISpotifyState, IStoreState } from "../types/index";
import { WindowUtils } from "../utils/WindowUtils";
import { ReducerUtils } from "./reducer-utils";

function spotify(
  spotifyState: ISpotifyState = {
    isLoggedIn: false,
    performingAction: false,
    trackPlayer: {
      defaultDurationMs: defaultDurationMs,
      isPlaying: false,
      currentlyPlayingTrackIndex: 0,
      percentageDone: 0,
      isLoading: false
    },
    playlistStore: {
      playlists: [],
    },
  },
  action: SpotifyActions
) {
  const utils = new ReducerUtils(spotifyState);
  switch (action.type) {
    case getType(toggleSaveTrackToLibrary):
      const track = action.payload;
      spotifyState.trackPlayer.focusedPlaylist
        ?.getTrackList()
        .getTracks()
        .forEach((t, index) => {
          if (track.getId() === t.getId()) {
            t.toggleDoesUserLikeTrackDangerously();
          }
        });
      return {
        ...spotifyState,
      };
    case getType(skipToNextPreviewTrack):
      const nextIndex = spotifyState.trackPlayer.currentlyPlayingTrackIndex + 1;
      const numberTracksInPlaylist =
        spotifyState.trackPlayer.focusedPlaylist!.getNumberOfItemsInPlaylist();
      const maxIndex = numberTracksInPlaylist - 1;
      const skippedAheadTrackPlayer = {
        ...spotifyState.trackPlayer,
        currentlyPlayingTrackIndex:
          nextIndex <= maxIndex ? nextIndex : maxIndex,
        startTime: new Date(),
        targetEndTime: DateUtils.addMillisecondsToDate(
          new Date(),
          spotifyState.trackPlayer.defaultDurationMs
        ),
      };
      return {
        ...spotifyState,
        trackPlayer: skippedAheadTrackPlayer,
      };
    case getType(skipToPreviousPreviewTrack):
      const previousIndex =
        spotifyState.trackPlayer.currentlyPlayingTrackIndex - 1;
      const skippedBehindTrackPlayer = {
        ...spotifyState.trackPlayer,
        currentlyPlayingTrackIndex: previousIndex >= 0 ? previousIndex : 0,
        startTime: new Date(),
        targetEndTime: DateUtils.addMillisecondsToDate(
          new Date(),
          spotifyState.trackPlayer.defaultDurationMs
        ),
      };
      return {
        ...spotifyState,
        trackPlayer: skippedBehindTrackPlayer,
      };
    case getType(addFiveSeconds):
      const addFiveSecondsPlayer = {
        ...spotifyState.trackPlayer,
        targetEndTime: DateUtils.addMillisecondsToDate(
          spotifyState.trackPlayer.targetEndTime!,
          spotifyState.trackPlayer.defaultDurationMs
        ),
      };
      return {
        ...spotifyState,
        trackPlayer: addFiveSecondsPlayer,
      };

    case getType(updatePreviewTrackPlaying):
      const trackPlayer = {
        ...spotifyState.trackPlayer,
        currentlyPlayingTrackIndex: action.payload,
        startTime: new Date(),
        targetEndTime: DateUtils.addMillisecondsToDate(
          new Date(),
          spotifyState.trackPlayer.defaultDurationMs
        ),
      };
      return { ...spotifyState, trackPlayer: trackPlayer };
    case getType(updateArtistPlaying):
      return {
        ...spotifyState,
        selectedArtist: action.payload,
      };
    case getType(startPlayingTracks):
    case getType(playPreviewTrack):
      const trackPlayerStarting = {
        ...spotifyState.trackPlayer,
        isPlaying: true,
        startTime: new Date(),
        targetEndTime: DateUtils.addMillisecondsToDate(
          new Date(),
          spotifyState.trackPlayer.defaultDurationMs
        ),
      };

      return { ...spotifyState, trackPlayer: trackPlayerStarting };
    case getType(startPlayingPlaylistRadio):
      WindowUtils.goToRadio();
      return {
        ...spotifyState,
        trackPlayer: {
          ...spotifyState.trackPlayer,
          isPlaying: false,
          startTime: undefined,
          targetEndTime: undefined,
          currentlyPlayingTrackIndex: 0,
          percentageDone: 0,
          focusedPlaylist: undefined,
          isLoading: true
        },
      };
    case getType(receivedPlaylistForRapidRadio):
      return {
        ...spotifyState,
        trackPlayer: {
          ...spotifyState.trackPlayer,
          isPlaying: false,
          startTime: undefined,
          targetEndTime: undefined,
          currentlyPlayingTrackIndex: 0,
          percentageDone: 0,
          focusedPlaylist: action.payload,
          isLoading: false
        },
      };
    case getType(playlistDeleted):
      return {
        ...spotifyState,
        playlistStore: utils.getPlaylistStoreWithoutPlaylist(spotifyState.playlistStore, action.payload),
        trackPlayer: {
          ...spotifyState.trackPlayer,
          isPlaying: false,
          startTime: undefined,
          targetEndTime: undefined,
          currentlyPlayingTrackIndex: 0,
          percentageDone: 0,
          focusedPlaylist: action.payload,
          isLoading: false
        },
      };
    case getType(pauseTrack):
      const trackPlayedPaused = {
        ...spotifyState.trackPlayer,
        isPlaying: false,
      };

      return { ...spotifyState, trackPlayer: trackPlayedPaused };
    case getType(eventLoop):
    case getType(checkToSeeIfShouldSkipToNextTrack):
      // if (!utils.isEndTimeDefined()) {
      //   throw new Error("end time is not defined");
      // }

      // if (!utils.isTrackPlaying()) {
      //   throw new Error("track is not playing");
      // }
      // if (!utils.isThereAPlaylistPlaying()) {
      //   throw new Error("expected playlist to be playing");
      // }
      const trackPlayerWithUpdatedPercentages = {
        ...spotifyState.trackPlayer,
        percentageDone: utils.getPercentageTimeRemaining(),
      };

      if (utils.areTheTracksDonePlaying()) {
        const trackPlayerUpdated = {
          ...trackPlayerWithUpdatedPercentages,
          isPlaying: false,
          startTime: undefined,
          targetEndTime: undefined,
        };
        return { ...spotifyState, trackPlayer: trackPlayerUpdated };
      }
      if (utils.isItTimeForNextTrack()) {
        const nextTrackIndex = utils.getNextTrackIndex();
        const trackPlayerUpdated = {
          ...trackPlayerWithUpdatedPercentages,
          currentlyPlayingTrackIndex: nextTrackIndex,
          startTime: new Date(),
          targetEndTime: DateUtils.addMillisecondsToDate(
            new Date(),
            spotifyState.trackPlayer.defaultDurationMs
          ),
        };
        return { ...spotifyState, trackPlayer: trackPlayerUpdated };
      } else {
        return {
          ...spotifyState,
          trackPlayer: trackPlayerWithUpdatedPercentages,
        };
      }
    case RECEIVED_USER_SUCCESS:
      return { ...spotifyState, user: action.user };
    case RECEIVED_DEVICE_LIST_SUCCESS:
      return { ...spotifyState, deviceList: action.deviceList };
    case RECEIVED_PLAYBACK_SUCCESS:
      return {
        ...spotifyState,
        whatsPlaying: action.whatsPlaying,
        selectedArtist: action.whatsPlaying.getArtist(),
      };
    case REQUEST_USER_REQUEST:
      console.log(
        "//todo: dispatch sync action saying that user data is being loaded here"
      );
      return { ...spotifyState };
    case RECEIVED_PLAYLIST_SUCCESS:
      return {
        ...spotifyState,
        playlistStore: {
          playlists: action.playlists,
        },
      };
    case USER_SELECTED_ARTIST:
      return { ...spotifyState, selectedArtist: action.artist };
    case SCROLL_TO_PLAYLIST_VIEW:
      if (WindowUtils.isMobileWidthScreen()) {
        setTimeout(function () {
          const doc = document as Document;
          const querySelector = doc.querySelector(".playlistView") as Element;
          querySelector.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
        }, 100);
      }

      return { ...spotifyState };
    case PERFORMED_ACTION:
      return { ...spotifyState, performingAction: false };
    case PERFORMING_AN_ACTION:
      return { ...spotifyState, performingAction: true };
    case LOGIN:
      const hostname = window.location.hostname;
      const port = window.location.port;
      let hostAndPort;
      if (port) {
        hostAndPort = "https://" + hostname + ":" + port;
      } else {
        hostAndPort = "https://songcompass.com";
      }

      window.location.href =
        "https://accounts.spotify.com/authorize?" +
        "client_id=5f086ac14e0848f29b36842835db054b&response_type=token&" +
        "redirect_uri=" +
        hostAndPort +
        "&" +
        "scope=streaming%20" +
        "user-read-currently-playing%20" +
        "user-modify-playback-state%20" +
        "user-top-read%20" +
        "user-library-modify%20" +
        "user-library-read%20" +
        "user-read-playback-state%20" +
        "playlist-modify-private%20" +
        "playlist-read-private%20" +
        "user-read-private%20" +
        "playlist-modify-public";

      return { ...spotifyState };
    default:
      return spotifyState;
  }
}

function general(
  generalState: IGeneralState = {
    notifications: [],
    notificationDrawerOpen: false,
    applicationExceptions: [],
  },
  action: GeneralActions
) {
  switch (action.type) {
    case ACKNOWLEDGE_NOTIFICATION:
      const notifications = generalState.notifications.filter((n) => {
        return !n.isEqual(action.notification);
      });
      const shouldDrawerStillBeOpen = notifications.length > 0;
      return {
        ...generalState,
        notifications: notifications,
        notificationDrawerOpen: shouldDrawerStillBeOpen,
      };
    case RECEIVED_ARTIST_PLAYLIST:
    case RECEIVED_GENRE_PLAYLIST:
    case RECEIVED_ARTIST_PLAYLIST_SINGULAR:
      const notification = new PlaylistNotification({
        message: `Created playlist`,
        playlist: action.playlist,
      });
      return {
        ...generalState,
        notifications: generalState.notifications.concat([notification]),
      };
    // case DELETED_PLAYLIST:
    //   const notification = new PlaylistNotification({
    //     message: `Deleted playlist`,
    //     playlist: action.playlist,
    //   });
    //   return {
    //     ...generalState,
    //     notifications: generalState.notifications.concat([notification]),
    //   };
    case TOGGLE_DRAWER:
      return {
        ...generalState,
        notificationDrawerOpen: !generalState.notificationDrawerOpen,
      };
    case OPEN_DRAWER:
      return {
        ...generalState,
        notificationDrawerOpen: true,
      };
    case ERROR_PUBLISHED:
      return {
        ...generalState,
        applicationExceptions: [
          ...generalState.applicationExceptions,
          action.applicationException,
        ],
      };
    default:
      return generalState;
  }
}

let iStoreStateReducer = combineReducers({
  spotify,
  general,
  loadingBar: loadingBarReducer,
});
const reducer = iStoreStateReducer as any as Reducer<IStoreState>;
export default reducer;
