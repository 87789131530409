import * as React from "react";
import { DocsItem } from "./docs-item";

type Props = { initiallyOpen: boolean };
export function FeedbackDocsItem({ initiallyOpen }: Props) {
  return (
    <DocsItem header={"Feedback"} initiallyOpen={initiallyOpen}>
      <div id="help">
        <p>
          I would appreciate feedback! Suggestions and bug reports are welcome.
        </p>
        <p>Contact: songcompasshelp@gmail.com</p>
      </div>
    </DocsItem>
  );
}
