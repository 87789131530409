import { SpotifyPlaylist } from "spotify-utils";
import {
  IOpenDrawer,
  IPublishedError,
  IToggleDrawer,
  SpotifyActions
} from "../../actions/general-actions";
import * as constants from "../../constants/constants";
import { DateUtils } from "../../date-utils";
import { Utils } from "../../utils/Utils";

export type GeneralActions =
  | IAcknowledgeNotification
  | SpotifyActions
  | IToggleDrawer
  | IPublishedError
  | IOpenDrawer;

export interface IAcknowledgeNotification {
  type: constants.ACKNOWLEDGE_NOTIFICATION;
  notification: GenericNotification;
}

type GenericNotificationArgs = {
  message: string;
  showTimer?: boolean;
};

export class GenericNotification {
  public instant: Date;
  public uniqueId: number;
  public message: string;
  public showTimer: boolean;

  constructor(args: GenericNotificationArgs) {
    this.instant = new Date();
    this.uniqueId = Utils.generateRandomNaturalNumber();
    this.message = args.message;
    if (args.showTimer !== undefined) {
      this.showTimer = args.showTimer as boolean;
    } else {
      this.showTimer = true;
    }
  }

  public getTimeSinceOccurrence(currentTime: Date) {
    return DateUtils.getHumanDuration(this.instant, currentTime);
  }

  public generateAcknowledgeAction(): IAcknowledgeNotification {
    return {
      type: constants.ACKNOWLEDGE_NOTIFICATION,
      notification: this
    };
  }
  public isEqual(notification: GenericNotification) {
    return notification.uniqueId === this.uniqueId;
  }
}

export class PlaylistNotification extends GenericNotification {
  public playlist: SpotifyPlaylist;

  constructor(args: GenericNotificationArgs & { playlist: SpotifyPlaylist }) {
    super(args);
    this.playlist = args.playlist;
  }
}
