import * as React from "react";
import { connect } from "react-redux";
import { SpotifyArtist, SpotifyArtistList, WhatsPlaying } from "spotify-utils";
import { IStoreState } from "../../types/index";
import { Grid } from "../grid/grid";
import { ArtistAnchor } from "./artist-anchor";

type OwnProps = {
  sortByPopularity: boolean;
};
type Props = {
  whatsPlaying: WhatsPlaying;
  artists: SpotifyArtistList;
} & OwnProps;

class Raw extends React.Component<Props> {
  private getSortedArtists(artists: SpotifyArtistList) {
    if (this.props.sortByPopularity) {
      return artists.getSortedByPopularity();
    }
    return artists.getArtists();
  }

  render() {
    const playingArtistGenres = this.props.whatsPlaying.getArtistGenres();
    const artistsProcessed = this.getSortedArtists(this.props.artists);

    const anchors = artistsProcessed.map((artist: SpotifyArtist) => {
      return (
        <ArtistAnchor
          artist={artist}
          key={artist.getId()}
          playingArtistGenres={playingArtistGenres}
          artists={artistsProcessed}
        />
      );
    });

    return <Grid>{anchors}</Grid>;
  }
}

const ArtistGrid = connect((state: IStoreState) => {
  return {
    whatsPlaying: state.spotify.whatsPlaying!,
    artists: state.spotify.whatsPlaying!.getRelatedArtists()
  };
})(Raw);
export { ArtistGrid };
