import * as React from "react";
import { Link } from "react-router-dom";

type Props = { href: string; title: string };

export function FooterLink({ href, title }: Props) {
  return (
    <Link className={"footer-link"} to={href}>
      <h3>{title}</h3>
    </Link>
  );
}
