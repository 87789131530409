import * as React from "react";
import { connect } from "react-redux";
import { SpotifyUser } from "spotify-utils";
import "../../index.scss";
import { IStoreState } from "../../types/index";

type Props = { user?: SpotifyUser };
function UserName({ user }: Props) {
  if (user) {
    return (
      <div className="userName">
        <img src={user && user.getFirstImageUrl()} alt={"User Profile"} />
      </div>
    );
  } else {
    return null;
  }
}

export function mapStateToProps(state: IStoreState) {
  return {
    user: state.spotify.user
  };
}

export default connect(mapStateToProps)(UserName);
