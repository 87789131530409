export const LOGIN = "LOGIN";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type LOGIN = typeof LOGIN;

export const CREATE_ARTIST_PLAYLIST = "CREATE_ARTIST_PLAYLIST";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CREATE_ARTIST_PLAYLIST = typeof CREATE_ARTIST_PLAYLIST;

export const RECEIVED_ARTIST_PLAYLIST = "RECEIVED_ARTIST_PLAYLIST";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RECEIVED_ARTIST_PLAYLIST = typeof RECEIVED_ARTIST_PLAYLIST;

export const ACKNOWLEDGE_NOTIFICATION = "ACKNOWLEDGE_NOTIFICATION";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ACKNOWLEDGE_NOTIFICATION = typeof ACKNOWLEDGE_NOTIFICATION;

export const ERROR_PUBLISHED = "ERROR_PUBLISHED";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ERROR_PUBLISHED = typeof ERROR_PUBLISHED;

export const ACKNOWLEDGE_ERROR = "ACKNOWLEDGE_ERROR";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ACKNOWLEDGE_ERROR = typeof ACKNOWLEDGE_ERROR;

export const TOGGLE_DRAWER = "TOGGLE_DRAWER";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type TOGGLE_DRAWER = typeof TOGGLE_DRAWER;

export const OPEN_DRAWER = "OPEN_DRAWER";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type OPEN_DRAWER = typeof OPEN_DRAWER;

export const PERFORMING_AN_ACTION = "PERFORMING_AN_ACTION";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PERFORMING_AN_ACTION = typeof PERFORMING_AN_ACTION;
export const PERFORMED_ACTION = "PERFORMED_ACTION";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PERFORMED_ACTION = typeof PERFORMED_ACTION;

export const RECEIVED_ARTIST_PLAYLIST_SINGULAR =
  "RECEIVED_ARTIST_PLAYLIST_SINGULAR";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RECEIVED_ARTIST_PLAYLIST_SINGULAR =
  typeof RECEIVED_ARTIST_PLAYLIST_SINGULAR;

export const DELETED_PLAYLIST = "DELETED_PLAYLIST";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type DELETED_PLAYLIST = typeof DELETED_PLAYLIST;

export const RECEIVED_GENRE_PLAYLIST = "RECEIVED_GENRE_PLAYLIST";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RECEIVED_GENRE_PLAYLIST = typeof RECEIVED_GENRE_PLAYLIST;

export const USER_SELECTED_ARTIST = "USER_SELECTED_ARTIST";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type USER_SELECTED_ARTIST = typeof USER_SELECTED_ARTIST;

export const SCROLL_TO_PLAYLIST_VIEW = "SCROLL_TO_PLAYLIST_VIEW";

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SCROLL_TO_PLAYLIST_VIEW = typeof SCROLL_TO_PLAYLIST_VIEW;

export const RECEIVED_USER_SUCCESS = "RECEIVED_USER_SUCCESS";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RECEIVED_USER_SUCCESS = typeof RECEIVED_USER_SUCCESS;

export const RECEIVED_DEVICE_LIST_SUCCESS = "RECEIVED_DEVICE_LIST_SUCCESS";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RECEIVED_DEVICE_LIST_SUCCESS = typeof RECEIVED_DEVICE_LIST_SUCCESS;

export const RECEIVED_PLAYBACK_SUCCESS = "RECEIVED_PLAYBACK_SUCCESS";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RECEIVED_PLAYBACK_SUCCESS = typeof RECEIVED_PLAYBACK_SUCCESS;

export const RECEIVED_PLAYLIST_SUCCESS = "RECEIVED_PLAYLIST_SUCCESS";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RECEIVED_PLAYLIST_SUCCESS = typeof RECEIVED_PLAYLIST_SUCCESS;

//
// export const DONE_LOADING_RAPID_RADIO = "DONE_LOADING_RAPID_RADIO";
// // eslint-disable-next-line @typescript-eslint/no-redeclare
// export type DONE_LOADING_RAPID_RADIO = typeof DONE_LOADING_RAPID_RADIO;

export const REQUEST_USER_REQUEST = "REQUEST_USER_REQUEST";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type REQUEST_USER_REQUEST = typeof REQUEST_USER_REQUEST;

export const APP = "APP";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type APP = typeof APP;

export const defaultDurationMs = 15000;
