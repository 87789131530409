import * as React from "react";
import { FaWikipediaW } from "react-icons/fa";
import { SvgLink } from "./svg-link";

export function WikipediaLink(props: { href: string }) {
  return (
    <SvgLink href={props.href}>
      <FaWikipediaW />
    </SvgLink>
  );
}
