import * as React from "react";
import { Route } from "react-router";
import { RoutingHrefs } from "../../../constants/routing-hrefs";
import { WindowUtils } from "../../../utils/WindowUtils";
import { AboutDocsItem } from "../about-docs-item";
import { FaqDocsItem } from "../faq-docs-item";
import { FeedbackDocsItem } from "../feedback-docs-item";
import { PlaylistDocsItem } from "../playlist-docs-item";
import { RoadmapDocsItem } from "../roadmap-docs-item";
import { FooterLink } from "./footer-link";

export function DocsPageBody() {
  return (
    <div id="docsPageBody">
      <Route
        path={RoutingHrefs.playlistsHref}
        render={() => <PlaylistDocsItem initiallyOpen={true} />}
      />
      <Route
        path={RoutingHrefs.roadmapHref}
        render={() => <RoadmapDocsItem initiallyOpen={true} />}
      />
      <Route
        path={RoutingHrefs.aboutHref}
        render={() => <AboutDocsItem initiallyOpen={true} />}
      />
      <Route
        path={RoutingHrefs.feedbackHref}
        render={() => <FeedbackDocsItem initiallyOpen={true} />}
      />
      <Route
        path={RoutingHrefs.faqHref}
        render={() => <FaqDocsItem initiallyOpen={true} />}
      />
      <Route
        path={RoutingHrefs.docsHref}
        exact={true}
        render={() => {
          return (
            <>
              <PlaylistDocsItem initiallyOpen={true} />
              <RoadmapDocsItem initiallyOpen={true} />
              <AboutDocsItem initiallyOpen={true} />
              <FeedbackDocsItem initiallyOpen={true} />
              <FaqDocsItem initiallyOpen={true} />
            </>
          );
        }}
      />
      {WindowUtils.isMobileWidthScreen() && (
        <>
          <br />
          <br />
          <br />
          <FooterLink href={RoutingHrefs.docsHref} title={"Show all Docs"} />
          <br />
          <FooterLink href={RoutingHrefs.root} title={"Go To Main App"} />
        </>
      )}
    </div>
  );
}
