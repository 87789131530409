import * as React from "react";
import { connect } from "react-redux";
import { Route } from "react-router";
import { RoutingHrefs } from "../../constants/routing-hrefs";
import "../../index.scss";
import { IStoreState } from "../../types/index";
import { Docs } from "../docs/layout/docs";
import { ErrorPage } from "../error-pages/error-page";
import { FirstTip } from "../error-pages/first-tip";
import ScLoginCallToAction from "../error-pages/login-call-to-action";
import { NoActiveDevicesErrorPage } from "../error-pages/no-active-devices-error-page";
import { Header } from "../header/header";
import { PlaylistList } from "../playlist-list/playlist-list";
import { RapidRadio } from "../rapid-radio/rapid-radio";
import { MainApp } from "./main-app";

function SongCompass() {
  return (
    <>
      <Route path={RoutingHrefs.docsHref} component={Docs} />
      <Route path={RoutingHrefs.login} component={ScLoginCallToAction} />
      <Route path={RoutingHrefs.root} render={() => <MainApp />} exact />
      <Route
        path={RoutingHrefs.rapidRadio}
        render={() => {
          return (
            <div className="plainPanel">
              <Header />
              <div className={"plainBody"}>
                <RapidRadio />
              </div>
            </div>
          );
        }}
        exact
      />
      <Route
        path={RoutingHrefs.playlists}
        render={() => {
          return (
            <div className="plainPanel">
              <Header />
              <div className={"plainBody"}>
                <PlaylistList />
              </div>
            </div>
          );
        }}
        exact
      />
      <Route
        path={RoutingHrefs.firstTip}
        render={() => {
          return (
            <div className="plainPanel">
              <Header />
              <FirstTip />
            </div>
          );
        }}
        exact
      />
      <Route
        path={RoutingHrefs.errorPage}
        component={() => {
          return <ErrorPage />;
        }}
      />
      <Route
        path={RoutingHrefs.noActiveDevices}
        component={() => {
          return <NoActiveDevicesErrorPage />;
        }}
      />
    </>
  );
}

export function mapStateToProps(state: IStoreState) {
  return {
    isLoggedIn: state.spotify.isLoggedIn,
  };
}

export default connect(mapStateToProps)(SongCompass);
