import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { SpotifyArtist } from "spotify-utils";
import {
  scrollToPlaylistView,
  userSelectedArtist
} from "../../actions/general-actions";
import { ActionButtonGreen } from "../buttons/action-button-green";
import { ArtistTooltip } from "./artist-tooltip";

type OwnProps = {
  artist: SpotifyArtist;
  playingArtistGenres: string[];
  artists: SpotifyArtist[];
};
type Props = {
  onUserSelectArtist: () => void;
} & OwnProps;

const Raw = (props: Props) => (
  <ActionButtonGreen
    onClick={props.onUserSelectArtist.bind(props.artist)}
    disabled={false}
    className={""}
    tooltip={
      <ArtistTooltip
        artist={props.artist}
        playingArtistGenres={props.playingArtistGenres}
        onUserSelectArtist={props.onUserSelectArtist}
      />
    }
  >
    <div
      className="popularityBar"
      style={{
        width: getWidthOfPopularityBar(props.artist, props.artists) + "%"
      }}
    />
    {props.artist.getNameOfArtist()}
  </ActionButtonGreen>
);

function getMinPopularity(artists: SpotifyArtist[]) {
  const values = artists.map(a => a.getPopularity());
  return Math.min(...values);
}

function getMaxPopularity(artists: SpotifyArtist[]) {
  const values = artists.map(a => a.getPopularity());
  return Math.max(...values);
}

function getDomain(artists: SpotifyArtist[]) {
  return [getMinPopularity(artists), getMaxPopularity(artists)];
}

function getWidthOfPopularityBar(
  artist: SpotifyArtist,
  artists: SpotifyArtist[]
) {
  const domain = getDomain(artists);
  const domainRange = domain[1] - domain[0];
  const multiplier = 100 / domainRange;

  const min = domain[0];
  // console.log(domain, "domain");
  // const max = domain[1];
  // it 75-25 = 50
  // 75 -> 100
  // 25 -> 0
  // 25 - 25 = 0
  // ...
  // 75-25 = 50
  // 50 * (100/50)
  const pop = artist.getPopularity();

  const widthDiff = pop - min;
  const width = widthDiff * multiplier;
  return width;
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    onUserSelectArtist: function(this: SpotifyArtist) {
      dispatch(userSelectedArtist(this));
      dispatch(scrollToPlaylistView());
    }
  };
}

const ArtistAnchor = connect(
  null,
  mapDispatchToProps
)(Raw);
export { ArtistAnchor };
