import * as React from "react";
import { MdDelete } from "react-icons/md";
import { connect } from "react-redux";
import { hideLoading, showLoading } from "react-redux-loading-bar";
import { SpotifyFactory } from "spotify-utils";
import { SpotifyPlaylistCommon } from "spotify-utils/dist/src/domain/SpotifyPlaylist";
import { playlistDeleted } from "../../actions/general-actions";
import { IStoreState, SCMapDispatchToProps } from "../../types/index";
import { ActionButton } from "../buttons/action-button";

type StoreProps = {
  spotifyToken?: string;
};

type OwnProps = {
  playlist: SpotifyPlaylistCommon;
};
type DispatchProps = {
  deletePlaylist: (playlist: SpotifyPlaylistCommon, spotifyToken: string) => void;
};
type ConnectedProps = StoreProps & DispatchProps;
type Props = ConnectedProps & OwnProps;
const Raw: React.FC<Props> = props => {
  if (!props.spotifyToken) {
    return null;
  }
  return (
    <ActionButton
      onClick={async () => {
        props.deletePlaylist(props.playlist, props.spotifyToken as string);
      }}
      disabled={false}
      className={""}
    >
      <MdDelete />
    </ActionButton>
  );
};

const mapDispatchToProps: SCMapDispatchToProps<DispatchProps> = (
  dispatch: any
) => {
  return {
    deletePlaylist: async (playlist: SpotifyPlaylistCommon, spotifyToken: string) => {
      dispatch(showLoading());
      const spotifyApi = SpotifyFactory.getSpotifyApi({
        spotify: { token: spotifyToken }
      });
      await spotifyApi.initialize();
      await spotifyApi.deletePlaylist(playlist);
      dispatch(playlistDeleted(playlist));
      console.log("deleted playlist");
      dispatch(hideLoading());
    }
  };
};
const mapStateToProps = (state: IStoreState) => {
  return {
    spotifyToken: state.spotify.token
  };
};
const DeletePlaylistButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(Raw);

export { DeletePlaylistButton };
