import * as React from "react";
import "./link.scss";

export const SvgLink: React.FC<{ href: string }> = props => {
  return (
    <a href={props.href} className={"svg-link"}>
      {props.children}
    </a>
  );
};
