import classnames from "classnames";
import * as React from "react";
import { FaHeart } from "react-icons/fa";
import { connect } from "react-redux";
import { SpotifyPlaylist, SpotifyTrack } from "spotify-utils";
import { SpotifyArtistSimplified } from "spotify-utils/dist/src/domain/SpotifyArtistSimplified";
import {
  checkToSeeIfShouldSkipToNextTrack,
  pausePlayback,
  skipToNextPreviewTrack,
  startPlayingTracks,
  updateArtistPlayingAction,
  updatePreviewTrackPlaying,
} from "../../actions/general-actions";
import { IStoreState, TrackPlayerStore } from "../../types/index";
import { TrackTitleWithDetails } from "../details-view/track-title-with-details";
import { DeletePlaylistButton } from "../header/delete-playlist-button";

import "./track-list.scss";

type OwnProps = { playlist: SpotifyPlaylist };
type DispatchProps = {
  startPlayingTracks: () => void;
  skipToNextPreviewTrack: () => void;
  updateArtistPlaying: (artist: SpotifyArtistSimplified) => void;
  pausePlayback: () => void;
  updatePreviewTrackPlaying: (index: number) => void;
  checkPlayback: (inMs: number) => void;
};
type StoreProps = {
  trackPlayer: TrackPlayerStore;
};
type Props = DispatchProps & OwnProps & StoreProps;
// type State = { trackPlayer: TrackPlayer };
type State = {};

function buildAudioElementId(t: SpotifyTrack, index: number) {
  return "audio" + t.getId() + "-" + index;
}

class Raw extends React.Component<Props, State> {
  // constructor(props: Props) {
  //   super(props);
  // console.log(props, "props");
  // const playlistTracks = props.playlist.getTrackList().getTracks();
  // this.state = {
  //   trackPlayer: new TrackPlayer(playlistTracks, {
  //     onChange: async (track: SpotifyTrack, index: number) => {
  //       // this.props.updatePreviewTrackPlaying(track);
  //       console.warn("track", track);
  //       await this.playPreviewTrack(track, index);
  //     }
  //   })
  // };
  // }

  shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<State>,
    nextContext: any
  ): boolean {
    const isPlaylistDifferent = this.isPlaylistDifferent(
      nextProps.playlist,
      this.props.playlist
    );
    const isPlayingTrackDifferent = this.isNextTrackDifferent(
      nextProps,
      this.props
    );

    const isPlayStateDifferent = this.isPlayStateDifferent(
      nextProps.trackPlayer,
      this.props.trackPlayer
    );

    const shouldUpdate =
      isPlaylistDifferent || isPlayingTrackDifferent || isPlayStateDifferent;

    // console.log(isPlayStateDifferent, "isPlayStateDifferent");
    // console.log(isPlayingTrackDifferent, "isPlayingTrackDifferent");
    // console.log(isPlaylistDifferent, "isPlaylistDifferent");
    // console.log(shouldUpdate, "shouldUpdate");

    return shouldUpdate;
  }

  private isNextTrackDifferent(
    nextProps: Readonly<Props>,
    currentProps: Readonly<Props>
  ) {
    const nextPlayingTrack = nextProps.trackPlayer.currentlyPlayingTrackIndex;
    const currentPlayingTrack =
      currentProps.trackPlayer.currentlyPlayingTrackIndex;
    if (nextPlayingTrack === undefined) {
      if (currentPlayingTrack === undefined) {
        return false;
      } else {
        return true;
      }
    } else {
      if (currentPlayingTrack === undefined) {
        return true;
      } else {
        return currentPlayingTrack !== nextPlayingTrack;
      }
    }
  }

  componentDidUpdate(): void {
    console.log("CDM");
    if (this.isPlaying()) {
      console.log("has index.. playing preview");
      const track = this.getCurrentlyPlayingTrack();
      this.playPreviewTrack(track, this.getIndex());
    } else {
      console.log("pausing track");
      this.pauseAllTracks();
    }
  }

  componentDidMount(): void {
    console.log("CDM");
    if (this.isPlaying()) {
      console.log("has index.. playing preview");
      const track = this.getCurrentlyPlayingTrack();
      this.playPreviewTrack(track, this.getIndex());
    } else {
      this.pauseAllTracks();
      console.log("pausing track");
    }
  }

  private getCurrentlyPlayingTrack() {
    return this.props.playlist.getTrackList().getTracks()[this.getIndex()];
  }

  private haveIndex() {
    return this.getIndexRaw() !== undefined;
  }

  private getIndex(): number {
    if (!this.haveIndex()) {
      throw new Error("does not exist");
    }
    return this.getIndexRaw()!;
  }

  private getIndexRaw() {
    return this.props.trackPlayer.currentlyPlayingTrackIndex;
  }

  render() {
    const currentlyPlayingTrackIndex =
      this.props.trackPlayer.currentlyPlayingTrackIndex;
    if (currentlyPlayingTrackIndex !== undefined) {
      this.props.checkPlayback(this.props.trackPlayer.defaultDurationMs);
    }

    const trackList = this.props.playlist.getTrackList();
    const tracks = trackList.getTracks();

    const trackListJSX = tracks.map((t: SpotifyTrack, index: number) => {
      const audioKey = buildAudioElementId(t, index);
      const areTrackIdsTheSame =
        this.props.trackPlayer.currentlyPlayingTrackIndex === index;

      const classes = classnames({
        "playing-track": areTrackIdsTheSame,
        "invalid-track": !t.hasPreviewUrl(),
      });
      const listItem = (
        <li className={classes} key={audioKey}>
          {t.getFirstArtist().getNameOfArtist()} - {t.getTrackName()} - P:{" "}
          {t.getPopularity()}{" "}
          {t.isKnownWhetherUserLikesTrack() && t.doesUserLikeTrack() ? (
            <FaHeart style={{ width: "1rem", height: "1rem", color: "red" }} />
          ) : null}
          {t.hasPreviewUrl() && (
            <audio id={audioKey} src={t.getPreviewUrl()!} />
          )}
          {areTrackIdsTheSame}
        </li>
      );

      return listItem;
    });
    return (
      <>
        <TrackTitleWithDetails track={this.getCurrentlyPlayingTrack()} />
        <h4>Playlist {this.props.playlist.getName()}</h4>
        <DeletePlaylistButton playlist={this.props.playlist} />
        <ul className={"playlist-track-list"}>{trackListJSX}</ul>
      </>
    );
  }

  componentWillUnmount(): void {
    console.log("TrackList will unmount");
  }

  private async playPreviewTracks(tracks: SpotifyTrack[]) {
    console.log(tracks);
    this.props.startPlayingTracks();
    // startPlayingTracks()
    // this.state.trackPlayer.startPlayingTracks();
    // for await (const [index, track] of tracks.entries()) {
    //   const id = buildAudioElementId(track, index);
    //   const audioTrack = document.querySelector(`#${id}`) as HTMLAudioElement;
    //   await this.stopAllAudioPlayback();
    //   if (track.getPreviewUrl() === null) {
    //     continue;
    //   }
    //   console.log(`playing ${track.getTrackName()} ${track.getPreviewUrl()}`);
    //
    //   audioTrack.play().then(() => {});
    //   this.props.updatePreviewTrackPlaying(track);
    //   await Utils.resolveAfter3Seconds();
    // }
  }

  private async playPreviewTrack(track: SpotifyTrack, index: number) {
    if (!track.hasPreviewUrl()) {
      this.props.skipToNextPreviewTrack();
    }
    const id = buildAudioElementId(track, index);
    const audioTrack = document.querySelector(`#${id}`) as HTMLAudioElement;
    console.log(`playing ${track.getTrackName()} ${track.getPreviewUrl()}`);
    this.props.updateArtistPlaying(track.getFirstArtist());
    await this.stopAllAudioPlayback();

    audioTrack.play().then(() => {});
    // this.props.updatePreviewTrackPlaying(index);
  }

  private async pauseAllTracks() {
    return this.stopAllAudioPlayback();
  }

  private async stopAllAudioPlayback() {
    const elementNodeListOf: NodeListOf<HTMLAudioElement> =
      document.querySelectorAll("html audio");
    for await (const el of elementNodeListOf) {
      await el.pause();
    }
  }

  private isPlaying() {
    return this.props.trackPlayer.isPlaying;
  }

  private isPlaylistDifferent(
    playlist: SpotifyPlaylist,
    playlist2: SpotifyPlaylist
  ) {
    return playlist.getId() !== playlist2.getId();
  }

  private isPlayStateDifferent(
    trackPlayer: TrackPlayerStore,
    trackPlayer2: TrackPlayerStore
  ) {
    return trackPlayer.isPlaying !== trackPlayer2.isPlaying;
  }
}

const TrackList = connect(
  (initialState: IStoreState) => {
    return {
      trackPlayer: initialState.spotify.trackPlayer,
    };
  },
  (dispatch) => ({
    startPlayingTracks: () => {
      const thunk = (dispatch: any, getState: () => IStoreState) => {
        debugger;
        dispatch(pausePlayback({ ignoreIfNotPlaying: true }) as any);

        dispatch(startPlayingTracks());
        setTimeout(() => {
          dispatch(checkToSeeIfShouldSkipToNextTrack());
        }, getState().spotify.trackPlayer.defaultDurationMs);
      };
      dispatch(thunk as any);
    },
    checkPlayback: (inMs: number) => {
      setTimeout(() => {
        dispatch(checkToSeeIfShouldSkipToNextTrack());
      }, inMs);
    },
    pausePlayback: () => {
      const dispatcher = pausePlayback({ ignoreIfNotPlaying: false });
      dispatch(dispatcher as any);
    },
    updatePreviewTrackPlaying: (index: number) => {
      dispatch(updatePreviewTrackPlaying(index));
    },
    updateArtistPlaying: (artist: SpotifyArtistSimplified) => {
      dispatch(updateArtistPlayingAction(artist) as any);
    },
    skipToNextPreviewTrack: () => {
      dispatch(skipToNextPreviewTrack());
    },
  })
)(Raw);

export { TrackList };
