import * as React from "react";
import { SpotifyTrack } from "spotify-utils";
import { WikipediaLink } from "../links/wikipedia-link";

import "./track-title.scss";

type Props = { track: SpotifyTrack };

function getWikipediaLinkToArtists(artists: string[]) {
  return `https://en.wikipedia.org/w/index.php?search=${artists.join("%20")}`;
}

export function TrackTitleWithDetails({ track }: Props) {
  const artists = track.getArtistNames();
  const href = getWikipediaLinkToArtists(artists);

  function getAllMusicLinkToArtists(artists: string[]) {
    return `https://www.google.com/search?q=site%3Aallmusic.com+${artists.join(
      " "
    )}`;
  }

  const allMusicLinkToArtists = getAllMusicLinkToArtists(artists);
  const artistsText = artists.join(" & ");
  return (
    <div className="header">
      <h4>
        <div style={{ marginLeft: "1rem", color: "#da00ff" }}>
          {artistsText}
        </div>
      </h4>
      <h4>
        <div style={{ fontStyle: "italic" }}>{track.getTrackName()}</div>&nbsp;
      </h4>
      <div className={"links"}>
        <WikipediaLink href={href} />
        <a href={allMusicLinkToArtists}>AM</a>
        <a
          href={`https://www.google.com/search?q=site%3Areddit.com+${artists.join(
            " "
          )}`}
        >
          Reddit
        </a>
      </div>
    </div>
  );
}
