import * as React from "react";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { IStoreState } from "../../types/index";
import {
  GenericNotification,
  PlaylistNotification,
} from "./GenericNotification";
import { NotificationItem } from "./notification-item";
import { PlaylistNotificationComponent } from "./playlist-notification/playlist-notification-component";

function Raw(props: Props) {
  const hasNotifications = props.notifications.length > 0;
  if (!hasNotifications) {
    const notification = new GenericNotification({
      message: "No notifications to show yet",
      showTimer: false,
    });

    return (
      <div className="notificationsList">
        <NotificationItem notification={notification} />
      </div>
    );
  }

  const notificationsJsx = props.notifications.map(
    (notification: PlaylistNotification) => {
      const notificationItem: any = (
        <NotificationItem
          key={notification.uniqueId}
          notification={notification}
        >
          <PlaylistNotificationComponent playlist={notification.playlist} />
        </NotificationItem>
      );
      return notificationItem;
    }
  );
  return <div className="notificationsList">{notificationsJsx}</div>;
}

type StateProps = { notifications: PlaylistNotification[] };
type DispatchProps = { acknowledgeNotification: any };
type ConnectedProps = DispatchProps & StateProps;
type OwnProps = {};
type Props = ConnectedProps & OwnProps;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, IStoreState> = (
  state
) => {
  return {
    notifications: state.general.notifications,
  };
};
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch
) => {
  return {
    acknowledgeNotification: function (this: GenericNotification) {
      dispatch<any>(this.generateAcknowledgeAction());
    },
  };
};
const NotificationList = connect(mapStateToProps, mapDispatchToProps)(Raw);

export { NotificationList };
