import * as React from "react";
import { NavLink } from "react-router-dom";

type Props = {
  to: string;
  children: React.ReactNode;
};

export const ActiveNavLink = ({ to, children }: Props) => {
  return (
    <NavLink to={to} activeClassName={"active-nav-link"} exact>
      {children}
    </NavLink>
  );
};
