import * as React from "react";
import { MdList } from "react-icons/md";
import { Link } from "react-router-dom";
import { RoutingHrefs } from "../../constants/routing-hrefs";

export class FirstTip extends React.Component<{}> {
  public render() {
    return (
      <div className="plainBody">
        <h1>Welcome to Song Compass</h1>
        <h4>
          Explore your music by playing something in Spotify and refreshing this
          page.
        </h4>
        <h4>
          You can also quickly explore your playlists here
          <Link to={RoutingHrefs.playlists}>
            <MdList color={"red"} />
          </Link>
        </h4>
      </div>
    );
  }
}
