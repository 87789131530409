import classNames from "classnames";
import * as React from "react";
import { MdNotifications, MdNotificationsNone } from "react-icons/md";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { toggleDrawer } from "../../actions/general-actions";
import "../../index.scss";
import { IStoreState } from "../../types/index";
import { GenericNotification } from "./GenericNotification";
import { NotificationList } from "./notification-list";
import "./notifications.scss";

function Raw({
  notifications,
  notificationDrawerOpen,
  isLoggedIn,
  toggleDrawer
}: Props) {
  if (!isLoggedIn) {
    return null;
  }
  const hasNotifications = notifications.length > 0;

  const drawerClasses = classNames({
    notificationDrawerOpen: notificationDrawerOpen,
    notificationDrawer: true
  });

  const drawer = (
    <div className={drawerClasses} onClick={toggleDrawer}>
      <MdNotificationsNone />
      <MdNotifications className="hoverDrawer" />
      {hasNotifications && (
        <div id="badge">
          <span className="circle" />
          <span id="badgeCountNumber">{notifications.length}</span>
        </div>
      )}
    </div>
  );

  return (
    <div className="notifications">
      {drawer}
      {notificationDrawerOpen && <NotificationList />}
    </div>
  );
}

type StateProps = {
  notifications: GenericNotification[];
  notificationDrawerOpen: boolean;
  isLoggedIn: boolean;
};
type DispatchProps = {
  toggleDrawer: any;
};
type ConnectedProps = DispatchProps & StateProps;
type OwnProps = {};
type Props = ConnectedProps & OwnProps;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  IStoreState
> = state => {
  return {
    notifications: state.general.notifications,
    notificationDrawerOpen: state.general.notificationDrawerOpen,
    isLoggedIn: state.spotify.isLoggedIn
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  OwnProps
> = dispatch => {
  return {
    toggleDrawer: function() {
      dispatch<any>(toggleDrawer());
    }
  };
};
const NotificationListDrawer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Raw);

export { NotificationListDrawer };
