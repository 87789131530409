import * as React from "react";

export function SongPopularity() {
  return (
    <p>
      The popularity is calculated by Spotify's algorithms. Popularity is mainly
      the play count with an emphasis placed on the recent play count. Your
      favorite band that released a new album will have extra weight given to
      their new work. The primary factor is play count but songs played more
      recently will show up higher on the list.
    </p>
  );
}
