import * as React from "react";
import { Link } from "react-router-dom";
import { RoutingHrefs } from "../../constants/routing-hrefs";

export class WelcomePage extends React.Component<{}> {
  public componentDidMount() {
    window.scrollTo(0, 0);
  }
  public render() {
    return (
      <div>
        <h4>
          Song Compass connects with your Spotify account allowing you to
          explore your currently playing music and
          <Link className="green-link" to={RoutingHrefs.playlistsHref}>
            &nbsp;generate playlists&nbsp;
          </Link>
          from it.
        </h4>
      </div>
    );
  }
}
