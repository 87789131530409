import * as React from "react";
import { MdPlaylistAdd } from "react-icons/md";
import { ActionButtonGreen } from "./action-button-green";
import "./action-button.scss";

type Props = { onClick: any; disabled: boolean; title: string };
export const PlaylistActionButton = (props: Props) => (
  <ActionButtonGreen
    onClick={props.onClick}
    disabled={props.disabled}
    className={`playlist-action-button`}
  >
    <span>{props.title}</span>
    <MdPlaylistAdd />
  </ActionButtonGreen>
);
