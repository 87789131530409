import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { SpotifyArtist, SpotifyImage } from "spotify-utils";
import { IStoreState } from "../../types/index";
import "./artist-image.scss";

type Props = { artist?: SpotifyArtist };
type State = { isToggled: boolean };

class Raw extends React.Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = { isToggled: false };
  }

  genImage(image: SpotifyImage, isToggled: boolean) {
    const classes = classNames({
      "artist-image-expanded": isToggled,
      "artist-image": true
    });

    return (
      <div className={"artist-image-wrapper"}>
        <img
          className={classes}
          src={image.getUrl()}
          alt={"Artist"}
          key={image.getUrl()}
        />
      </div>
    );
  }

  toggle() {
    this.setState({
      isToggled: !this.state.isToggled
    });
  }

  render() {
    if (!this.props.artist) {
      return null;
    }
    const image = this.props.artist.getFirstImage();
    return this.genImage(image, this.state.isToggled);
  }
}

export default connect((state: IStoreState) => {
  const whatsPlaying = state.spotify.whatsPlaying;
  if (whatsPlaying) {
    return {
      artist: whatsPlaying.getArtist()
    };
  } else {
    return {
      artist: undefined
    };
  }
})(Raw);
