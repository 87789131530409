import * as React from "react";
import { RoutingHrefs } from "../../../constants/routing-hrefs";
import { ActiveNavLink } from "../mini-components/active-nav-link";

export function DocsSidebar() {
  return (
    <div id="docsPageSidebar">
      <ul style={{ listStyleType: "none", padding: 0 }}>
        <li>
          <ActiveNavLink to={RoutingHrefs.playlistsHref}>
            Playlists
          </ActiveNavLink>
        </li>
        <li>
          <ActiveNavLink to={RoutingHrefs.roadmapHref}>Roadmap</ActiveNavLink>
        </li>
        <li>
          <ActiveNavLink to={RoutingHrefs.aboutHref}>About</ActiveNavLink>
        </li>

        <li>
          <ActiveNavLink to={RoutingHrefs.feedbackHref}>Feedback</ActiveNavLink>
        </li>
        <li>
          <ActiveNavLink to={RoutingHrefs.faqHref}>FAQ</ActiveNavLink>
        </li>
        <br />
        <li>
          <ActiveNavLink to={RoutingHrefs.docsHref}>Show All</ActiveNavLink>
        </li>
      </ul>
    </div>
  );
}
