import * as React from "react";
import { connect, MapStateToProps } from "react-redux";
import { WhatsPlaying } from "spotify-utils";
import { SpotifyPlaylistSimplified } from "spotify-utils/dist/src/domain/SpotifyPlaylist";
import { ReducerUtils } from "../../reducers/reducer-utils";
import { IStoreState, PlaylistsStore, TrackPlayerStore } from "../../types/index";
import { DeletePlaylistButton } from "../header/delete-playlist-button";
import { PlayPlaylistOnRadioButton } from "../header/play-playlist-on-radio-button";
import "./playlist-list.scss";

type StateProps = {
  whatsPlaying?: WhatsPlaying;
  trackPlayer: TrackPlayerStore;
  reducerUtils: ReducerUtils;
  playlistStore: PlaylistsStore;
};
type DispatchProps = {};

type ConnectedProps = DispatchProps & StateProps;
type OwnProps = {};
type Props = ConnectedProps & OwnProps;

const Raw: React.FC<Props> = (props) => {
  return (
    <div>
      <div style={{ marginTop: "1rem" }}>
        <ul>
          {props.playlistStore.playlists.map((playlist: SpotifyPlaylistSimplified) => {
            return (
              <li key={playlist.getId()}>
                {playlist.getName()}
                <PlayPlaylistOnRadioButton playlist={playlist} />
                <DeletePlaylistButton playlist={playlist} />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, IStoreState> = (
  state
) => {
  return {
    whatsPlaying: state.spotify.whatsPlaying,
    trackPlayer: state.spotify.trackPlayer,
    reducerUtils: new ReducerUtils(state.spotify),
    playlistStore: state.spotify.playlistStore,
  };
};
const PlaylistList = connect(mapStateToProps, {})(Raw);

export { PlaylistList };
