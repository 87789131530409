import * as React from "react";
import { MdPlayCircleOutline } from "react-icons/md";
import { connect } from "react-redux";
import { SpotifyPlaylist } from "spotify-utils";
import { startPlayingPlaylist } from "../../actions/general-actions";
import { SCMapDispatchToProps, SCMapStoreToProps } from "../../types/index";
import { ActionButton } from "../buttons/action-button";

type StoreProps = {
  spotifyToken?: string;
};

type DispatchProps = {
  startPlayingPlaylist: (spotifyPlaylist: SpotifyPlaylist) => any;
};
type OwnProps = {
  playlist: SpotifyPlaylist;
};

type Props = StoreProps & OwnProps & DispatchProps;

const Raw: React.FC<Props> = props => {
  if (!props.spotifyToken) {
    return null;
  }
  const onClick = async () => {
    props.startPlayingPlaylist(props.playlist);
  };
  return (
    <ActionButton onClick={onClick} disabled={false}>
      <MdPlayCircleOutline />
    </ActionButton>
  );
};

const mapStateToProps: SCMapStoreToProps<StoreProps> = state => {
  return {
    spotifyToken: state.spotify.token
  };
};

const mapDispatchToProps: SCMapDispatchToProps<DispatchProps> = dispatch => {
  return {
    startPlayingPlaylist: (playlist: SpotifyPlaylist) => {
      dispatch(startPlayingPlaylist(playlist));
    }
  };
};
const PlayPlaylistButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(Raw);

export { PlayPlaylistButton };
