import {
  addMilliseconds,
  differenceInMilliseconds,
  distanceInWordsStrict,
  isAfter,
} from "date-fns";

export class DateUtils {
  static getHumanDuration(startTime: Date, endTime: Date) {
    return distanceInWordsStrict(startTime, endTime) + " ago";
  }

  static getDurationMilliseconds(startTime: Date, endTime: Date) {
    return differenceInMilliseconds(startTime, endTime);
  }

  static addMillisecondsToDate(startTime: Date, ms: number) {
    return addMilliseconds(startTime, ms);
  }

  static getMillisecondsFromNow(targetEndTime: Date) {
    return this.getDurationMilliseconds(new Date(), targetEndTime);
  }

  static isXAfterY(date: Date, date2: Date) {
    return isAfter(date, date2);
  }

  static getPerecentageDone(startTime: Date, currentTime: Date, endTime: Date) {
    if (
      !this.isXAfterY(currentTime, startTime) ||
      !this.isXAfterY(endTime, currentTime)
    ) {
      return 1;
      // throw new Error(
      //   `Invariant violation ${startTime} ${currentTime} ${endTime}`
      // );
    }
    const msDone = differenceInMilliseconds(currentTime, startTime);
    const msTotal = differenceInMilliseconds(endTime, startTime);
    return msDone / msTotal;
  }
}
