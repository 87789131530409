import * as React from "react";
import { MdRadio } from "react-icons/md";
import { connect, MapDispatchToProps } from "react-redux";
import { SpotifyPlaylistCommon } from "spotify-utils/dist/src/domain/SpotifyPlaylist";
import { switchToRapidRadioCompositeAction } from "../../actions/general-actions";
import { ActionButton } from "../buttons/action-button";

type StoreProps = {};

type DispatchProps = {
  startPlaylistRadio: (spotifyPlaylist: SpotifyPlaylistCommon) => any;
};
type OwnProps = {
  playlist: SpotifyPlaylistCommon;
};

type Props = StoreProps & OwnProps & DispatchProps;

const Raw: React.FC<Props> = (props) => {
  return (
    <ActionButton
      onClick={() => {
        props.startPlaylistRadio(props.playlist);
      }}
      disabled={false}
      className={""}
    >
      <MdRadio color={"deepskyblue"} />
    </ActionButton>
  );
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch
) => {
  return {
    startPlaylistRadio: function (spotifyPlaylist: SpotifyPlaylistCommon) {
      dispatch<any>(switchToRapidRadioCompositeAction(spotifyPlaylist));
    },
  };
};
const PlayPlaylistOnRadioButton = connect(null, mapDispatchToProps)(Raw);

export { PlayPlaylistOnRadioButton };
