import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { SpotifyArtist, WhatsPlaying } from "spotify-utils";
import { IStoreState } from "../../types/index";
import { ActionButtonGreenAnchor } from "../buttons/action-button-green-anchor";
import { Grid } from "../grid/grid";

function buildGenresWithHighlighting(
  genres: string[],
  genresToHighlight: string[]
) {
  function shouldHighlightGenre(genre: string) {
    return genresToHighlight.indexOf(genre) !== -1;
  }
  const genresJsx = genres.map(function (genre: string) {
    const genreClasses = classNames({
      highlightGenre: shouldHighlightGenre(genre),
    });

    const href = `https://open.spotify.com/search/songs/genre:"${genre}"`;
    return (
      <ActionButtonGreenAnchor href={href} className={genreClasses} key={genre}>
        {genre}
      </ActionButtonGreenAnchor>
    );
  });

  if (genres.length === 0) {
    return (
      <div>
        <h5>Genres</h5>
        <span>
          Spotify has not classified this artist's genres yet. You can still
          create playlists below though!
        </span>
      </div>
    );
  }

  return (
    <div className="genres">
      <h5>Genres</h5>
      <Grid>{genresJsx}</Grid>
    </div>
  );
}

type Props = {
  whatsPlaying: WhatsPlaying;
  selectedArtist: SpotifyArtist;
  showSelectedArtistGenres: boolean;
};
const Raw = ({
  whatsPlaying,
  selectedArtist,
  showSelectedArtistGenres,
}: Props) => {
  if (showSelectedArtistGenres) {
    const selectedArtistGenres = selectedArtist.getGenres();
    // hacking here..
    // const currentSongGenres = whatsPlaying.getArtistGenres();
    let currentSongGenres: string[] = [];

    return buildGenresWithHighlighting(selectedArtistGenres, currentSongGenres);
  } else {
    const currentSongGenres = whatsPlaying.getArtistGenres();

    if (selectedArtist) {
      const selectedArtistGenres = selectedArtist.getGenres();
      return buildGenresWithHighlighting(
        currentSongGenres,
        selectedArtistGenres
      );
    } else {
      return buildGenresWithHighlighting(currentSongGenres, []);
    }
  }
};

function mapStateToProps(
  state: IStoreState,
  ownProps: { showSelectedArtistGenres: boolean }
) {
  return {
    selectedArtist: state.spotify.selectedArtist!,
    whatsPlaying: state.spotify.whatsPlaying!,
    showSelectedArtistGenres: ownProps.showSelectedArtistGenres,
  };
}

const GenreGrid = connect(mapStateToProps)(Raw);

export { GenreGrid };
