import * as React from "react";
import { SongCompassWhite } from "../../images/song-compass-white";

export class DocsHeader extends React.Component {
  public render() {
    return (
      <div id="docsPageHeader">
        <SongCompassWhite width={"50px"} />
      </div>
    );
  }
}
