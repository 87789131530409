import * as React from "react";
import LoadingBar from "react-redux-loading-bar";
import "../../index.scss";
import SongCompass from "./song-compass";

function App() {
  return (
    <div id="app">
      <LoadingBar className="loadingBar" />
      <SongCompass />
    </div>
  );
}

export default App;
