import * as React from "react";
import { Header } from "../../header/header";

import "../docs.scss";
import { DocsHeader } from "./docs-header";
import { DocsPageBody } from "./docs-page-body";
import { DocsSidebar } from "./docs-sidebar";

export class Docs extends React.Component<{}> {
  public render() {
    let routerChildren = (
      <div id="docsPage">
        <DocsHeader />
        <DocsSidebar />
        <DocsPageBody />
      </div>
    );
    return (
      <>
        <Header />
        {routerChildren}
      </>
    );
  }
}
