import * as Sentry from "@sentry/browser";
import * as React from "react";
import * as ReactDOM from "react-dom";
import ReactGA from "react-ga";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import { AnyAction, applyMiddleware, createStore, Reducer } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
// import { createLogger } from "redux-logger";
import thunkMiddleware from "redux-thunk";
import {
  AppAction,
  eventLoop,
  initializeApplicationFromSpotify,
} from "./actions/general-actions";
import { SentryBoundary } from "./components/error-pages/sentry-boundary";
import App from "./components/layout/app";
import { defaultDurationMs } from "./constants/constants";
import "./index.scss";

import rootReducer from "./reducers/root-reducer";
import { IStoreState } from "./types/index";
import { SpotifyToken } from "./utils/SpotifyToken";

Sentry.init({
  dsn: "https://6b38e4cd5fc540efac86660bb1f6b2d8@sentry.io/1286297",
});

ReactGA.initialize("UA-126676764-1");
ReactGA.pageview(window.location.pathname + window.location.search);

declare module "redux" {
  // noinspection JSUnusedGlobalSymbols
  export type GenericStoreEnhancer = any;
}

// const loggerMiddleware = createLogger();

declare global {
  // noinspection JSUnusedGlobalSymbols
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__: any;
  }
}
const spotifyToken = new SpotifyToken();

const initialState: IStoreState = {
  spotify: {
    isLoggedIn: false,
    performingAction: false,
    deviceList: undefined,
    trackPlayer: {
      defaultDurationMs: defaultDurationMs,
      isPlaying: false,
      currentlyPlayingTrackIndex: 0,
      percentageDone: 0,
      isLoading: false
    },
    playlistStore: {
      playlists: [],
    },
  },
  general: {
    notifications: [],
    notificationDrawerOpen: false,
    applicationExceptions: [],
  },
  loadingBar: undefined,
};

function assignTokenAndLogUserIn(token: string) {
  initialState.spotify.isLoggedIn = true;
  initialState.spotify.token = token;
}

if (spotifyToken.doesTokenExistInHash()) {
  console.log("access token exists");
  const accessToken = spotifyToken.getAccessTokenFromHashFragment();
  assignTokenAndLogUserIn(accessToken);
  spotifyToken.removeTokenFromHashFragment();
  spotifyToken.storeTokenInLocalStorage(accessToken);
} else if (spotifyToken.doesTokenExistInLocalStorage()) {
  console.log("access token does not exist");
  assignTokenAndLogUserIn(spotifyToken.getTokenFromLocalStorage() as string);
}

const castRootReducer = rootReducer as Reducer<IStoreState, AnyAction>;

const store = createStore<IStoreState, AppAction, any, any>(
  castRootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(thunkMiddleware))
  // composeWithDevTools(applyMiddleware(thunkMiddleware, loggerMiddleware))
);
if (initialState.spotify.isLoggedIn) {
  console.log("dispatching fetch user action");
  store.dispatch(initializeApplicationFromSpotify());
  //todo: this is sketch.. not sure what will happen in exceptional cases
  // setInterval(() => {
  //   store.dispatch(refreshPlayback());
  // }, 60 * 1000);

  setInterval(() => {
    store.dispatch(eventLoop());
  }, 5000);
}

ReactDOM.render(
  <Provider store={store}>
    <SentryBoundary>
      <HashRouter>
        <App />
      </HashRouter>
    </SentryBoundary>
  </Provider>,
  document.getElementById("root") as HTMLElement
);
