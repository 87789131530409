import * as React from "react";
import { ActionButton, ActionButtonProps } from "./action-button";
import "./action-button.scss";

export const ActionButtonGreen: React.FC<ActionButtonProps> = props => (
  <ActionButton
    onClick={props.onClick}
    disabled={props.disabled}
    className={"action-button action-button-alternate"}
    tooltip={props.tooltip}
  >
    {props.children}
  </ActionButton>
);
