import * as Sentry from "@sentry/browser";
import * as React from "react";
import { Component } from "react";

export class SentryBoundary extends Component {
  constructor(props: any) {
    super(props);
    this.state = { error: null };
  }

  public componentDidCatch(error: any, errorInfo: any) {
    this.setState({ error });
    Sentry.configureScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
    });
    Sentry.captureException(error);
  }

  public render() {
    const onClick = () => Sentry.showReportDialog();
    const state = this.state as any;
    if (state.error) {
      return <button onClick={onClick}>Report feedback</button>;
    } else {
      return this.props.children;
    }
  }
}
