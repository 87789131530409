import * as React from "react";
import { MdList, MdRadio } from "react-icons/md";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { RoutingHrefs } from "../../constants/routing-hrefs";
import { IStoreState } from "../../types/index";
import { NotificationListDrawer } from "../notifications/notification-list-drawer";
import "./header.scss";
import { SongCompassLogo } from "./song-compass-logo";
import ScUserName from "./user-name";

type Props = { isLoggedIn: boolean; spotifyToken?: string };

// console.error("to", to);
const ScHeaderRaw = ({ isLoggedIn, spotifyToken }: Props) => (
  <header id="header">
    <div className="left-hand-header">
      <Link to={RoutingHrefs.root}>
        <SongCompassLogo />
      </Link>
      <Link to={RoutingHrefs.rapidRadio}>
        <MdRadio color={"deepskyblue"} />
      </Link>
      {/*<RemoteControl />*/}
      <Link to={RoutingHrefs.playlists}>
        <MdList color={"red"} />
      </Link>
    </div>
    {isLoggedIn && (
      <div className="rightHandSideHeader">
        <NotificationListDrawer />
        <ScUserName />
      </div>
    )}
  </header>
);

function mapStateToProps(state: IStoreState) {
  return {
    spotifyToken: state.spotify.token,
    isLoggedIn: state.spotify.isLoggedIn,
  };
}

const Header = connect(mapStateToProps)(ScHeaderRaw);
export { Header };
