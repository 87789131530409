import * as React from "react";
import "./action-button.scss";

type Props = { href: string; className: string };
export const ActionButtonGreenAnchor: React.FC<Props> = props => (
  <a
    href={props.href}
    className={`action-button action-button-alternate ${props.className}`}
  >
    {props.children}
  </a>
);
