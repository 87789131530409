import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { onLoginClick } from "../../actions/general-actions";
import "../../index.scss";
import { IStoreState } from "../../types/index";
import { ActionButtonGreen } from "../buttons/action-button-green";
import { SongCompassWhite } from "../images/song-compass-white";
import { WelcomePage } from "./welcome-page";

export interface Props {
  onLoginClick?: () => void;
}

class Raw extends React.Component<Props> {
  public getWelcomePage() {
    return <WelcomePage />;
  }

  public render() {
    const { onLoginClick } = this.props;
    const welcomePage = this.getWelcomePage();
    return (
      <div className="spotifyLogin">
        <SongCompassWhite width={"50px"} />
        {welcomePage}
        <ActionButtonGreen
          onClick={onLoginClick}
          disabled={false}
          className={""}
        >
          Login
        </ActionButtonGreen>
      </div>
    );
  }
}
function mapStateToProps(state: IStoreState) {
  return {
    isLoggedIn: state.spotify.isLoggedIn
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    onLoginClick: () => dispatch(onLoginClick())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Raw);
