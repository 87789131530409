import * as React from "react";
import { DocsItem } from "./docs-item";

type Props = { initiallyOpen: boolean };

export function AboutDocsItem({ initiallyOpen }: Props) {
  return (
    <DocsItem header={"About"} initiallyOpen={initiallyOpen}>
      <p>
        I created this app primarily because I wanted to know more about the
        artist I was listening to.
      </p>
      <p>Creating a radio station is ok for this but I wanted more control</p>
    </DocsItem>
  );
}
