import { RoutingHrefs } from "../constants/routing-hrefs";

export class WindowUtils {
  public static goToLoginScreen() {
    this.navigateTo(RoutingHrefs.login);
  }

  public static goToFirstTipScreen() {
    this.navigateTo(RoutingHrefs.firstTip);
  }

  public static isMobileWidthScreen() {
    const width = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    );
    return width < 800;
  }

  static goToMainApp() {
    this.navigateTo(RoutingHrefs.root);
  }

  private static navigateTo(href: string) {
    window.location.href = "/#" + href;
  }

  static refresh() {
    setTimeout(function () {
      console.log("Reloading!");
      window.location.reload();
    }, 1000);
  }

  static goToHomeScreenOrReloadInOneSecond() {
    setTimeout(function () {
      if (WindowUtils.isOnHomeScreen()) {
        console.log("Reloading!");
        window.location.reload();
      } else {
        console.log("Redirecting to main app");
        WindowUtils.goToMainApp();
        window.location.reload();
      }
    }, 1000);
  }

  private static isOnPage(href: string) {
    return window.location.hash === "#" + href;
  }

  private static isOnHomeScreen() {
    return this.isOnPage(RoutingHrefs.root);
  }

  public static goToErrorPage() {
    this.navigateTo(RoutingHrefs.errorPage);
  }

  public static goToNoActiveDevicesPage() {
    this.navigateTo(RoutingHrefs.noActiveDevices);
  }

  static goToRadio() {
    this.navigateTo(RoutingHrefs.rapidRadio);
  }

  static goToPlaylistScreen() {
    this.navigateTo(RoutingHrefs.playlists);
  }
}
