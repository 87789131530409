import * as React from "react";
import { connect } from "react-redux";
import { WhatsPlaying } from "spotify-utils";
import "../../index.scss";
import { IStoreState } from "../../types/index";
import { ArtistGridView } from "../artist-grid/artist-grid-view";
import { GenreGrid } from "../genres-grid/genre-grid";
import { TrackTitleWithDetails } from "./track-title-with-details";

type Props = { whatsPlaying: WhatsPlaying };
const PlaybackStatus = ({ whatsPlaying }: Props) => {
  if (whatsPlaying) {
    if (!whatsPlaying.isSomethingPlaying()) {
      throw new Error("not sure if need to handle this case");
    }
    const track = whatsPlaying.getTrack();

    return (
      <div className="playbackStatus">
        <h2 className="mobileTitle">Currently Playing</h2>
        <TrackTitleWithDetails track={track} />
        <GenreGrid showSelectedArtistGenres={false} />
        <ArtistGridView />
      </div>
    );
  }
  return null;
};

function mapStateToProps(state: IStoreState) {
  return {
    whatsPlaying: state.spotify.whatsPlaying!,
  };
}

export default connect(mapStateToProps)(PlaybackStatus);
