import * as React from "react";

type Props = { radius: number; stroke: number; progress: number };

export class ProgressRing extends React.Component<Props> {
  private normalizedRadius: number;
  private circumference: number;

  constructor(props: Props) {
    super(props);
    const { radius, stroke } = this.props;

    this.normalizedRadius = radius - stroke * 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI;
  }

  render() {
    const { radius, stroke, progress } = this.props;
    const strokeDashoffset =
      this.circumference - (progress / 100) * this.circumference;

    return (
      <svg height={radius * 2} width={radius * 2} className={"progress-ring"}>
        <circle
          stroke="white"
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={this.circumference + " " + this.circumference}
          style={{ strokeDashoffset }}
          r={this.normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <path
          stroke="#000"
          id="svg_7"
          transform="translate(20,20)"
          d="m4.096607,3.243409l-0.443686,27.420425l34.568451,16.59149l8.863706,-3.780339l-31.250031,-14.701321l-0.216124,-25.312547l-11.522316,-0.217707z"
          fillOpacity="null"
          strokeOpacity="null"
          strokeWidth="2"
          fill="#fff"
        />
      </svg>
    );
  }
}
