import classNames from "classnames";
import * as React from "react";
import { TiChartLineOutline } from "react-icons/ti";
import { connect } from "react-redux";
import { ArtistGrid } from "./artist-grid";

type State = { sortByPopularityOfArtist: boolean };

type Props = {};

class ArtistGridViewRaw extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = { sortByPopularityOfArtist: false };
  }

  public togglePopularitySorting() {
    this.setState((state) => {
      return { sortByPopularityOfArtist: !state.sortByPopularityOfArtist };
    });
  }

  private getTextToDescribeSortOrder(isPopularitySelected: boolean) {
    if (isPopularitySelected) {
      return (
        <h6>
          Most <span style={{ color: "orangeRed" }}>Popular</span>-&gt; Least{" "}
          <span style={{ color: "orangeRed" }}>Popular</span>
        </h6>
      );
    } else {
      return <h6>Most Related -&gt; Least Related</h6>;
    }
  }

  public render() {
    const sortByPopularity = this.state.sortByPopularityOfArtist;
    const svgClass = classNames({
      isSvgToggled: sortByPopularity,
      hoverableSvg: true,
    });
    const self = this;
    const togglePop = this.togglePopularitySorting.bind(this);
    return (
      <div className="relatedArtists">
        <h5 className="centeredHeader">
          Related Artists
          <TiChartLineOutline className={svgClass} onClick={togglePop} />
        </h5>
        {self.getTextToDescribeSortOrder(sortByPopularity)}

        <ArtistGrid sortByPopularity={sortByPopularity} />
      </div>
    );
  }
}

const ArtistGridView = connect()(ArtistGridViewRaw);

export { ArtistGridView };
