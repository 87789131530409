import ReactGA from "react-ga";
import { hideLoading, showLoading } from "react-redux-loading-bar";
import {
  DeviceList,
  PLAYER_NOT_FOUND,
  SpotifyArtist,
  SpotifyFactory,
  SpotifyPlaylist,
  SpotifyTrack,
  SpotifyTrackList,
  SpotifyUser,
  TRACK_NOT_FOUND,
  WhatsPlaying
} from "spotify-utils";
import { SpotifyArtistSimplified } from "spotify-utils/dist/src/domain/SpotifyArtistSimplified";
import { SpotifyPlaylistCommon, SpotifyPlaylistSimplified } from "spotify-utils/dist/src/domain/SpotifyPlaylist";
import { ActionType, createAction } from "typesafe-actions";
import * as constants from "../constants/constants";
import { SCROLL_TO_PLAYLIST_VIEW } from "../constants/constants";
import { ApplicationException } from "../exceptions/ApplicationException";
import { IStoreState } from "../types/index";
import { SpotifyToken } from "../utils/SpotifyToken";
import { Utils } from "../utils/Utils";
import { WindowUtils } from "../utils/WindowUtils";

export interface ILogin {
  type: constants.LOGIN;
}

export interface IAppAction {
  type: constants.APP;
}

export interface ICreateArtistPlaylist {
  type: constants.CREATE_ARTIST_PLAYLIST;
}

export type AppAction = IAppAction | ICreateArtistPlaylist;

export function onLoginClick(): ILogin {
  return {
    type: constants.LOGIN
  };
}

export interface IReceivedUser {
  type: constants.RECEIVED_USER_SUCCESS;
  user: SpotifyUser;
}

export interface IDeviceListAction {
  type: constants.RECEIVED_DEVICE_LIST_SUCCESS;
  deviceList: DeviceList;
}

export interface IPlayback {
  type: constants.RECEIVED_PLAYBACK_SUCCESS;
  whatsPlaying: WhatsPlaying;
}

export interface IRequestUser {
  type: constants.REQUEST_USER_REQUEST;
}

export function requestUserRequest(): IRequestUser {
  return {
    type: constants.REQUEST_USER_REQUEST
  };
}

export function receivedUserSuccess(user: SpotifyUser): IReceivedUser {
  return {
    type: constants.RECEIVED_USER_SUCCESS,
    user
  };
}

export function receivedDeviceList(deviceList: DeviceList): IDeviceListAction {
  return {
    type: constants.RECEIVED_DEVICE_LIST_SUCCESS,
    deviceList
  };
}

export function receivedPlaybackSuccess(whatsPlaying: WhatsPlaying) {
  return {
    type: constants.RECEIVED_PLAYBACK_SUCCESS,
    whatsPlaying
  };
}

export function receivedPlaylistSuccess(playlists: SpotifyPlaylistSimplified[]) {
  return {
    type: constants.RECEIVED_PLAYLIST_SUCCESS,
    playlists
  };
}

interface IReceivedPlaylists {
  type: constants.RECEIVED_PLAYLIST_SUCCESS;
  playlists: SpotifyPlaylistSimplified[];
}


interface IDoneLoadingRapidRadio {
  type: constants.RECEIVED_PLAYLIST_SUCCESS;
  playlists: SpotifyPlaylistSimplified[];
}

interface IReceivedArtistPlaylist {
  type: constants.RECEIVED_ARTIST_PLAYLIST;
  playlist: SpotifyPlaylist;
}

interface IReceivedArtistPlaylistSingular {
  type: constants.RECEIVED_ARTIST_PLAYLIST_SINGULAR;
  playlist: SpotifyPlaylist;
}

interface IReceivedArtistPlaylistSingular {
  type: constants.RECEIVED_ARTIST_PLAYLIST_SINGULAR;
  playlist: SpotifyPlaylist;
}

interface IReceivedGenrePlaylist {
  type: constants.RECEIVED_GENRE_PLAYLIST;
  playlist: SpotifyPlaylist;
}

export interface IToggleDrawer {
  type: constants.TOGGLE_DRAWER;
}

export interface IPublishedError {
  type: constants.ERROR_PUBLISHED;
  applicationException: ApplicationException;
}

export interface IOpenDrawer {
  type: constants.OPEN_DRAWER;
}

export interface IPerformingAnAction {
  type: constants.PERFORMING_AN_ACTION;
}

export interface IPerformedAction {
  type: constants.PERFORMED_ACTION;
}

interface IUserSelectedArtist {
  type: constants.USER_SELECTED_ARTIST;
  artist: SpotifyArtist;
}

export function userSelectedArtist(artist: SpotifyArtist) {
  return {
    type: constants.USER_SELECTED_ARTIST,
    artist
  };
}

export function scrollToPlaylistView() {
  return {
    type: constants.SCROLL_TO_PLAYLIST_VIEW
  };
}

export function receivedArtistPlaylist(
  playlist: SpotifyPlaylist
): IReceivedArtistPlaylist {
  return {
    type: constants.RECEIVED_ARTIST_PLAYLIST,
    playlist
  };
}

export function receivedArtistPlaylistSingular(
  playlist: SpotifyPlaylist
): IReceivedGenrePlaylist {
  return {
    type: constants.RECEIVED_GENRE_PLAYLIST,
    playlist
  };
}

export function receivedGenrePlaylist(
  playlist: SpotifyPlaylist
): IReceivedArtistPlaylistSingular {
  return {
    type: constants.RECEIVED_ARTIST_PLAYLIST_SINGULAR,
    playlist
  };
}

export function toggleDrawer(): IToggleDrawer {
  userActionEvent("Toggle notification drawer");
  return {
    type: constants.TOGGLE_DRAWER
  };
}

export function openDrawer() {
  return {
    type: constants.OPEN_DRAWER
  };
}

function performingAnAction() {
  return {
    type: constants.PERFORMING_AN_ACTION
  };
}

function performedAction() {
  return {
    type: constants.PERFORMED_ACTION
  };
}

function userActionEvent(event: string) {
  ReactGA.event({
    category: "User Action",
    action: event
  });
}

function pageViewEvent(view: string) {
  ReactGA.event({
    category: "View",
    action: view
  });
}

export function requestArtistPlaylist(
  artist: SpotifyArtist,
  config?: { startPlaying: boolean }
) {
  return (dispatch: any, getState: () => IStoreState) => {
    const spotifyApi = SpotifyFactory.getSpotifyApi({
      spotify: { token: getState().spotify.token as string }
    });
    dispatch(showLoading());
    dispatch(performingAnAction());

    userActionEvent("Getting related artists songs");

    const playlistDescription = `This playlist is generated by SongCompass (www.songcompass.com) and is the top 5 songs of similar artists to ${artist.getNameOfArtist()}.`;
    const playlistName = `[SC 🎧 - x5] ${artist.getNameOfArtist()}`;
    return spotifyApi
      .createPlaylistOfTopRelatedArtists(
        artist,
        playlistName,
        playlistDescription
      )
      .then(async function(playlist: SpotifyPlaylist) {
        if (config && config.startPlaying) {
          await spotifyApi.playPlaylist(playlist);
        }
        dispatch(receivedArtistPlaylist(playlist));
        dispatch(hideLoading());
        dispatch(performedAction());
        WindowUtils.refresh();
      })
      .catch(function(e: Error) {
        const message = `Failed to generate playlist ${artist.getNameOfArtist()}.`;
        new ApplicationException({
          message: message,
          error: e,
          details: "No details to add."
        }).dispatchAndRedirectToErrorPage(dispatch);
        dispatch(hideLoading());
        dispatch(performedAction());
      });
  };
}

export function startPlayingPlaylist(playlist: SpotifyPlaylist) {
  return (dispatch: any, getState: () => IStoreState) => {
    const spotifyApi = SpotifyFactory.getSpotifyApi({
      spotify: { token: getState().spotify.token as string }
    });
    dispatch(showLoading());
    dispatch(performingAnAction());
    userActionEvent("Playing playlist");

    return spotifyApi
      .playPlaylist(playlist)
      .then(function() {
        return Utils.resolveAfter2Seconds().then(() => {
          console.log(`resolved`);
          dispatch(refreshPlayback());
          dispatch(performedAction());
          dispatch(hideLoading());
        });
      })
      .catch(function(e: any) {
        const message = `Failed to start playlist ${playlist.getName()}.`;
        new ApplicationException({
          message: message,
          error: e,
          details: "No details to add."
        }).dispatchAndRedirectToErrorPage(dispatch);
        dispatch(hideLoading());
        dispatch(performedAction());
      });
  };
}

export function pausePlayback(p: { ignoreIfNotPlaying: boolean }) {
  return async(dispatch: any, getState: () => IStoreState) => {
    const spotifyApi = SpotifyFactory.getSpotifyApi({
      spotify: { token: getState().spotify.token as string }
    });
    dispatch(showLoading());
    dispatch(performingAnAction());
    userActionEvent("Pause playback");

    const whatsPlaying = await spotifyApi.whatsPlaying();
    if (!whatsPlaying.isSomethingPlaying() && p.ignoreIfNotPlaying) {
      dispatch(hideLoading());
      dispatch(performedAction());
      return;
    }
    return spotifyApi
      .pause()
      .then(function() {
        return Utils.resolveAfter1Seconds().then(() => {
          console.log(`resolved`);
          // dispatch(refreshPlayback());
          dispatch(performedAction());
          dispatch(hideLoading());
        });
      })
      .catch(function(e: any) {
        const message = `Failed to pause.`;
        new ApplicationException({
          message: message,
          error: e,
          details: "No details to add."
        }).dispatchAndRedirectToErrorPage(dispatch);
        dispatch(hideLoading());
        dispatch(performedAction());
      });
  };
}

export function resumePlayback() {
  return (dispatch: any, getState: () => IStoreState) => {
    const spotifyApi = SpotifyFactory.getSpotifyApi({
      spotify: { token: getState().spotify.token as string }
    });
    dispatch(showLoading());
    dispatch(performingAnAction());
    userActionEvent("Resume playback");

    return spotifyApi
      .play()
      .then(function() {
        return Utils.resolveAfter1Seconds().then(() => {
          console.log(`resolved`);
          // dispatch(refreshPlayback());
          dispatch(performedAction());
          dispatch(hideLoading());
        });
      })
      .catch(function(e: any) {
        const message = `Failed to pause.`;
        new ApplicationException({
          message: message,
          error: e,
          details: "No details to add."
        }).dispatchAndRedirectToErrorPage(dispatch);
        dispatch(hideLoading());
        dispatch(performedAction());
      });
  };
}

export function requestArtistPlaylistSingular(artist: SpotifyArtist) {
  userActionEvent("Getting top 10 songs");
  return (dispatch: any, getState: () => IStoreState) => {
    dispatch(showLoading());

    const spotifyApi = SpotifyFactory.getSpotifyApi({
      spotify: { token: getState().spotify.token as string }
    });
    const playlistName = `[SC 🎧 - Top 10] ${artist.getNameOfArtist()}`;
    const playlistDescription = `This playlist is generated by SongCompass (www.songcompass.com) and is the top 10 songs of the artist.`;
    return spotifyApi
      .createPlaylistOfTop10Songs(artist, playlistName, playlistDescription)
      .then(function(spotifyPlaylist: SpotifyPlaylist) {
        dispatch(receivedArtistPlaylistSingular(spotifyPlaylist));
        dispatch(hideLoading());
      });
  };
}

export function requestGenrePlaylist(genre: string) {
  userActionEvent("Getting Genre playlist");
  return (dispatch: any, getState: () => IStoreState) => {
    dispatch(showLoading());

    const spotifyApi = SpotifyFactory.getSpotifyApi({
      spotify: { token: getState().spotify.token as string }
    });
    const playlistName = `[SC 🎧 - ${genre}]`;
    const playlistDescription = `This playlist is generated by SongCompass (www.songcompass.com) and is the top songs from a genre.`;
    return spotifyApi
      .createPlaylistOfTop5ArtistsByGenre(
        genre,
        playlistName,
        playlistDescription
      )
      .then(function(playlist: SpotifyPlaylist) {
        dispatch(receivedGenrePlaylist(playlist));
        dispatch(hideLoading());
      });
  };
}

export const updatePreviewTrackPlaying = createAction(
  "UPDATE_PREVIEW_TRACK"
)<number>();

export const startPlayingTracks = createAction("START_PLAYING_TRACKS")<void>();
export const checkToSeeIfShouldSkipToNextTrack = createAction(
  "CHECK_TO_SEE_IF_SHOULD_SKIP_TO_NEXT_TRACK"
)<void>();
export const skipToNextPreviewTrack =
  createAction("SKIP_TO_NEXT_TRACK")<void>();
export const skipToPreviousPreviewTrack = createAction(
  "SKIP_TO_PREVIOUS_TRACK"
)<void>();
export const pauseTrack = createAction("PAUSE_TRACK")<void>();
export const startPlayingPlaylistRadio = createAction(
  "START_PLAYING_PLAYLIST_RADIO"
)<SpotifyPlaylistCommon>();
export const receivedPlaylistForRapidRadio = createAction(
  "RECEIVED_PLAYLIST_FOR_RAPID_RADIO"
)<SpotifyPlaylist>();
export const playlistDeleted = createAction(
  "PLAYLIST_DELETED"
)<SpotifyPlaylistCommon>();
export const playPreviewTrack = createAction("PLAY_PREVIEW_TRACK")<void>();
export const addFiveSeconds = createAction("ADD_FIVE_SECONDS")<void>();
export const eventLoop = createAction("EVENT_LOOP")<void>();
export const toggleSaveTrackToLibrary = createAction(
  "TOGGLE_SAVE_TRACK_TO_LIBRARY"
)<SpotifyTrack>();

type AllSpotifyTypeSafeActions =
  | ActionType<typeof updatePreviewTrackPlaying>
  | ActionType<typeof updateArtistPlaying>
  | ActionType<typeof startPlayingTracks>
  | ActionType<typeof skipToNextPreviewTrack>
  | ActionType<typeof skipToPreviousPreviewTrack>
  | ActionType<typeof addFiveSeconds>
  | ActionType<typeof startPlayingPlaylistRadio>
  | ActionType<typeof receivedPlaylistForRapidRadio>
  | ActionType<typeof playlistDeleted>
  | ActionType<typeof eventLoop>
  | ActionType<typeof pauseTrack>
  | ActionType<typeof playPreviewTrack>
  | ActionType<typeof toggleSaveTrackToLibrary>
  | ActionType<typeof checkToSeeIfShouldSkipToNextTrack>;

export type SpotifyActions =
  | IReceivedUser
  | IRequestUser
  | ILogin
  | IPlayback
  | IUserSelectedArtist
  | IPerformedAction
  | { type: SCROLL_TO_PLAYLIST_VIEW }
  | IPerformingAnAction
  | IReceivedArtistPlaylistSingular
  | IReceivedGenrePlaylist
  | IReceivedPlaylists
  | IDeviceListAction
  | AllSpotifyTypeSafeActions
  | IReceivedArtistPlaylist
  | IDoneLoadingRapidRadio;

export type IGetStateFunction = () => IStoreState;

function handleSomethingNotPlaying(dispatch: any) {
  pageViewEvent("No Song playing view");
  dispatch(hideLoading());
  // WindowUtils.goToFirstTipScreen();
}

function handleSpotifyError(err: any, dispatch: any) {
  dispatch(hideLoading());

  const ex: WebApiError = err;

  if (ex.statusCode === 401) {
    const spotifyToken = new SpotifyToken();
    spotifyToken.removeTokenFromLocalStorage();
    WindowUtils.goToLoginScreen();
  } else {
    switch(err.message) {
      case PLAYER_NOT_FOUND:
        // alert("player not found");
        WindowUtils.goToFirstTipScreen();
        break;
      case TRACK_NOT_FOUND:
        // alert("track not found");
        WindowUtils.goToFirstTipScreen();
        break;
      case "Unsuccessful HTTP response":
        pageViewEvent("Error.. please refresh page");
        WindowUtils.goToErrorPage();
        break;
      default:
        console.error(ex);
        new ApplicationException({
          error: ex,
          message: "Something terrible happened :(.",
          details: `Spotify Error: status code: <${ex.statusCode}> ${ex.message}`
        }).dispatchAndRedirectToErrorPage(dispatch);
        WindowUtils.goToErrorPage();

        pageViewEvent("Something terrible happened");
        dispatch(openDrawer());
    }
  }
}

export function refreshPlayback() {
  return async function(dispatch: any, getState: IGetStateFunction) {
    dispatch(showLoading());
    try {
      const spotifyApi = SpotifyFactory.getSpotifyApi({
        spotify: { token: getState().spotify.token as string }
      });
      await spotifyApi.initialize();
      const whatsPlaying: WhatsPlaying = await spotifyApi.whatsPlaying();
      if (!whatsPlaying.isSomethingPlaying()) {
        handleSomethingNotPlaying(dispatch);
        return;
      }

      dispatch(receivedPlaybackSuccess(whatsPlaying));
      dispatch(hideLoading());
    } catch (err) {
      handleSpotifyError(err, dispatch);
    }
  };
}

export function switchToMainPage() {
  return async function(dispatch: any, getState: IGetStateFunction) {
    WindowUtils.goToMainApp();
  };
}

export function switchToRadio() {
  return async function(dispatch: any, getState: IGetStateFunction) {
    WindowUtils.goToRadio();
  };
}

export const updateArtistPlaying = createAction(
  "UPDATE_ARTIST_PLAYING"
)<SpotifyArtist>();

export function updateArtistPlayingAction(artist: SpotifyArtistSimplified) {
  return async function(dispatch: any, getState: IGetStateFunction) {
    dispatch(showLoading());
    try {
      const spotifyApi = SpotifyFactory.getSpotifyApi({
        spotify: { token: getState().spotify.token as string }
      });
      await spotifyApi.initialize();
      const fullArtist = await spotifyApi.getFullArtist(artist);
      dispatch(updateArtistPlaying(fullArtist));
      dispatch(hideLoading());
    } catch (err) {
      handleSpotifyError(err, dispatch);
    }
  };
}

export function switchToRapidRadioCompositeAction(playlist: SpotifyPlaylistCommon) {
  return async function(dispatch: any, getState: IGetStateFunction) {
    dispatch(showLoading());
    dispatch(startPlayingPlaylistRadio(playlist))
    dispatch(loadDetailPlaylist(playlist));
    dispatch(hideLoading());
  };
}

export function loadDetailPlaylist(playlist: SpotifyPlaylistCommon) {
    return async function(dispatch: any, getState: IGetStateFunction) {
      dispatch(showLoading());
      try {
        const spotifyApi = SpotifyFactory.getSpotifyApi({
          spotify: { token: getState().spotify.token as string }
        });
        await spotifyApi.initialize();

        const spotifyPlaylist = await spotifyApi.getDecoratedPlaylist(playlist.getId());
        dispatch(receivedPlaylistForRapidRadio(spotifyPlaylist));
        dispatch(hideLoading());
      } catch (err) {
        handleSpotifyError(err, dispatch);
      }
    };
  }


  export function toggleSavedTrack(track: SpotifyTrack) {
    return async function(dispatch: any, getState: IGetStateFunction) {
      dispatch(showLoading());
      try {
        const spotifyApi = SpotifyFactory.getSpotifyApi({
          spotify: { token: getState().spotify.token as string }
        });
        await spotifyApi.initialize();

        if (track.doesUserLikeTrack()) {
          await spotifyApi.removeSavedTracks(new SpotifyTrackList([track]));
        } else {
          await spotifyApi.addToSavedTracks(new SpotifyTrackList([track]));
        }
        dispatch(toggleSaveTrackToLibrary(track));
        dispatch(hideLoading());
      } catch (err) {
        handleSpotifyError(err, dispatch);
      }
    };
  }

  export function initializeApplicationFromSpotify() {
    return async function(dispatch: any, getState: IGetStateFunction) {
      dispatch(requestUserRequest());
      dispatch(showLoading());
      try {
        const spotifyApi = SpotifyFactory.getSpotifyApi({
          spotify: { token: getState().spotify.token as string }
        });
        await spotifyApi.initialize();
        const deviceList = await spotifyApi.getDevices();
        console.log(deviceList, "deviceList");
        dispatch(receivedDeviceList(deviceList));
        dispatch(hideLoading());

        if (!deviceList.areThereActiveDevices()) {
          WindowUtils.goToPlaylistScreen();
          // WindowUtils.goToNoActiveDevicesPage();
        } else {
          const whatsPlaying: WhatsPlaying = await spotifyApi.whatsPlaying();
          if (!whatsPlaying.isSomethingPlaying()) {
            // handleSomethingNotPlaying(dispatch);
          } else {
            dispatch(receivedPlaybackSuccess(whatsPlaying));
          }
        }

        const playlists: SpotifyPlaylistSimplified[] =
          await spotifyApi.getMostRecentPlaylistsSimple();
        dispatch(receivedPlaylistSuccess(playlists));
        const user = await spotifyApi.getMyInfo();
        dispatch(hideLoading());
        dispatch(receivedUserSuccess(user));
        pageViewEvent("CurrentPlayingSong");
      } catch (err) {
        handleSpotifyError(err, dispatch);
      }
    };
  }

  interface WebApiError {
    name: string;
    message: string;
    statusCode: number;
  }
