import * as React from "react";

export function Top5TracksPer5ArtistsDocs() {
  return (
    <p>
      This is useful if you're listening to an artist you are familiar with but
      want to explore similar artists. Only the{" "}
      <span className="emphasis">top 5 songs</span> for{" "}
      <span className="emphasis2">each related Artist</span> will be used so it
      results in the "best" of what the artists have to offer.
    </p>
  );
}
