import classNames from "classnames";
import * as React from "react";
import { FaMinus, FaPlus } from "react-icons/fa";

type State = { isOpen: boolean };
type Props = { header: string; initiallyOpen?: boolean };
export class DocsItem extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const isOpen = !!props.initiallyOpen;
    this.state = { isOpen: isOpen };
  }

  private toggle() {
    this.setState((prevState: State) => ({
      isOpen: !prevState.isOpen
    }));
    console.log(this.state, "this.state");
  }

  public render() {
    const classes = classNames({
      docsBodyItemHeader: true,
      docsBodyItemHeaderOpen: this.state.isOpen
    });

    const bodyClasses = classNames({
      docsBodyItemContent: true,
      docsBodyItemContentOpen: this.state.isOpen
    });

    return (
      <div className="docsBodyItem">
        <div className={classes} onClick={this.toggle.bind(this)}>
          <h2>
            {this.props.header} {this.state.isOpen ? <FaMinus /> : <FaPlus />}
          </h2>
        </div>
        <div className={bodyClasses}>{this.props.children}</div>
      </div>
    );
  }
}
