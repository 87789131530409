export class SpotifyToken {
  public static getHashFragment() {
    const hash = window.location.hash;
    return hash.substr(1);
  }

  private tokenKey = "SPOTIFY_TOKEN";

  public storeTokenInLocalStorage(token: string) {
    window.localStorage.setItem(this.tokenKey, token);
  }

  public getTokenFromLocalStorage(): string | null {
    let tokenValue = window.localStorage.getItem(this.tokenKey);
    return tokenValue;
  }

  public getAccessTokenFromHashFragment() {
    const token = SpotifyToken.getHashFragment()
      .split("=")[1]
      .split("&")[0];
    return token;
  }

  public removeTokenFromHashFragment() {
    window.location.hash = "";
  }

  public doesTokenExistInHash() {
    const doesHashHaveValue = SpotifyToken.getHashFragment().length > 0;
    if (!doesHashHaveValue) {
      return false;
    } else {
      let doesAccessTokenExist = SpotifyToken.getHashFragment().includes(
        "access_token"
      );
      return doesAccessTokenExist;
    }
  }

  public doesTokenExistInLocalStorage() {
    return this.getTokenFromLocalStorage() !== null;
  }

  public removeTokenFromLocalStorage() {
    window.localStorage.removeItem(this.tokenKey);
  }
}
