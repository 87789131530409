import * as React from "react";
import songCompassWhite from "../../assets/songCompassWhite.svg";

type Props = { width?: string; id?: string };
export const SongCompassWhite = (props: Props) => {
  if (props.width) {
    return (
      <a href={"/"}>
        <img
          src={songCompassWhite}
          width={props.width}
          id={props.id}
          alt={"Song Compass"}
        />
      </a>
    );
  } else {
    return (
      <a href={"/"}>
        <img src={songCompassWhite} alt={"Song Compass"} id={props.id} />
      </a>
    );
  }
};
