import * as React from "react";
import { MdHelp } from "react-icons/md";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dispatch } from "redux";
import { SpotifyArtist } from "spotify-utils";
import {
  requestArtistPlaylist,
  requestArtistPlaylistSingular,
  requestGenrePlaylist
} from "../../actions/general-actions";
import { RoutingHrefs } from "../../constants/routing-hrefs";
import "../../index.scss";
import { IStoreState, VoidFn } from "../../types/index";
import { PlaylistActionButton } from "../buttons/playlist-action-button";
import { Grid } from "../grid/grid";

type Props = {
  selectedArtist: SpotifyArtist;
  onRequestArtistPlaylist: VoidFn;
  onRequestArtistPlaylistSingular: VoidFn;
  onRequestGenrePlaylist: VoidFn;
  performingAction: boolean;
};

function Raw({
  selectedArtist,
  onRequestArtistPlaylist,
  onRequestArtistPlaylistSingular,
  onRequestGenrePlaylist,
  performingAction
}: Props) {
  // const selectedArtistCast = selectedArtist as SpotifyArtist;

  if (selectedArtist) {
    const genreButtons = selectedArtist.getGenres().map(genre => {
      return (
        <PlaylistActionButton
          key={genre}
          onClick={onRequestGenrePlaylist.bind(genre)}
          disabled={performingAction}
          title={`${genre} Playlist`}
        />
      );
    });

    return (
      <div className="playlistView">
        <h4>
          <span className={"desktop"}>Playlist Creation Actions</span>
          <span className={"mobile"}>{selectedArtist.getNameOfArtist()}</span>
          <Link to={RoutingHrefs.playlistsHref} id={"helpPlaylist"}>
            <MdHelp />
          </Link>
        </h4>
        <Grid>
          <PlaylistActionButton
            onClick={onRequestArtistPlaylistSingular.bind(selectedArtist)}
            disabled={performingAction}
            title={`Top 10 songs`}
          />
          <PlaylistActionButton
            onClick={onRequestArtistPlaylist.bind(selectedArtist)}
            disabled={performingAction}
            title={`Top 5 related artists x top 3 tracks`}
          />
          {genreButtons}
        </Grid>
      </div>
    );
  }
  console.log("Returning null!");
  return null;
}

function mapStateToProps(state: IStoreState) {
  return {
    selectedArtist: state.spotify.selectedArtist!,
    performingAction: state.spotify.performingAction
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    onRequestArtistPlaylist: function(this: SpotifyArtist) {
      const spotifyArtist: SpotifyArtist = this as SpotifyArtist;
      dispatch<any>(requestArtistPlaylist(spotifyArtist));
    },
    onRequestArtistPlaylistSingular: function(this: SpotifyArtist) {
      dispatch<any>(requestArtistPlaylistSingular(this));
    },
    onRequestGenrePlaylist: function(this: string) {
      dispatch<any>(requestGenrePlaylist(this));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Raw);
