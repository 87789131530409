import * as React from "react";
import { DocsItem } from "./docs-item";
import { SongPopularity } from "./song-popularity";
import { Top10TracksPerPlaylistDocs } from "./top-10-tracks-per-playlist-docs";
import { Top5TracksPer5ArtistsDocs } from "./top-5-docs-per-playlist-docs";

type Props = { initiallyOpen: boolean };
export function PlaylistDocsItem({ initiallyOpen }: Props) {
  return (
    <>
      <DocsItem header={"Top 10 Tracks"} initiallyOpen={initiallyOpen}>
        <Top10TracksPerPlaylistDocs />
      </DocsItem>
      <DocsItem header={"Top 5 Tracks x Top 5 Artists"}>
        <Top5TracksPer5ArtistsDocs />
      </DocsItem>
      <DocsItem header={"Song Popularity"}>
        <SongPopularity />
      </DocsItem>
    </>
  );
}
