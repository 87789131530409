import * as React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { RoutingHrefs } from "../../constants/routing-hrefs";
import { ApplicationException } from "../../exceptions/ApplicationException";
import { IStoreState } from "../../types/index";
import "./error-page.scss";

const ErrorHeader: React.FC<{
  applicationExceptions: ApplicationException[];
}> = props => {
  const exceptions = props.applicationExceptions;
  if (exceptions.length === 0) {
    return (
      <h3>
        You're on the error page but there are no recorded errors in the
        application. Maybe go back to main page and refresh?
      </h3>
    );
  } else if (exceptions.length === 1) {
    return <h3>There was an error! Sorry about that. See details below.</h3>;
  } else {
    return <h3>There were many errors! See details below.</h3>;
  }
};

const ErrorPageRaw: React.FC<{
  applicationExceptions: ApplicationException[];
}> = props => {
  const header = (
    <ErrorHeader applicationExceptions={props.applicationExceptions} />
  );
  const errors = props.applicationExceptions.map(ae => {
    return (
      <div key={ae.message}>
        <div>{ae.message}</div>
        <div>{ae.details}</div>
        {/*todo: test sentry error boundary by removing json stringify*/}
        <div>{JSON.stringify(ae.error)}</div>
      </div>
    );
  });
  return (
    <div className="error-page">
      {header}
      <br />
      {errors}
      <NavLink to={RoutingHrefs.docsHref}>Docs</NavLink>
      <NavLink to={RoutingHrefs.root}>Home</NavLink>
    </div>
  );
};

const ErrorPage = connect((state: IStoreState) => {
  return {
    applicationExceptions: state.general.applicationExceptions
  };
})(ErrorPageRaw);
export { ErrorPage };
