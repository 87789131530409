export class RoutingHrefs {
  static readonly playlistsHref = "/docs/playlists";
  static readonly roadmapHref = "/docs/roadmap";
  static readonly aboutHref = "/docs/about";
  static readonly feedbackHref = "/docs/feedback";
  static readonly faqHref = "/docs/faq";
  static readonly docsHref = "/docs";
  static readonly login: string = "/login";
  static readonly firstTip: string = "/firstTip";
  static readonly root: string = "/";
  static readonly playlists: string = "/playlists";
  static readonly errorPage: string = "/error";
  static readonly noActiveDevices: string = "/noActiveDevices";
  static readonly rapidRadio = "/rapidRadio";
}
